export function Logo(): JSX.Element {
	return (
		<svg width='151' height='40' viewBox='0 0 151 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<g clipPath='url(#clip0_281_27516)'>
				<path
					d='M45.9621 8.27411H50.7689L59.2176 29.8177H54.6312L52.8112 25.0785H43.7637L41.9437 29.8177H37.4818L45.9621 8.27411ZM51.427 21.5089L48.286 13.538L45.1767 21.5089H51.427Z'
					fill='#283C55'
				/>
				<path
					d='M60.9437 14.3058H64.9334V16.214C65.8444 14.8906 67.7286 14.0552 69.7383 14.0552C74.4186 14.0552 77.5279 17.1639 77.5279 22.0571C77.5279 26.9502 74.4186 30.0646 69.7354 30.0646C67.7573 30.0646 65.8415 29.2029 64.9612 27.8794V35.4195H60.938L60.9437 14.3058ZM69.2363 26.7099C71.7489 26.7099 73.5076 24.8326 73.5076 22.0617C73.5076 19.2609 71.7489 17.3836 69.2363 17.3836C66.7237 17.3836 64.9641 19.2609 64.9641 22.0617C64.9641 24.8016 66.7237 26.7099 69.2363 26.7099Z'
					fill='#283C55'
				/>
				<path
					d='M79.4754 14.3058H83.4651V16.214C84.3751 14.8906 86.2602 14.0552 88.2699 14.0552C92.9502 14.0552 96.0596 17.1639 96.0596 22.0571C96.0596 26.9502 92.9502 30.0599 88.2699 30.0599C86.2918 30.0599 84.3751 29.1982 83.4957 27.8747V35.4148H79.4725L79.4754 14.3058ZM87.768 26.7099C90.2806 26.7099 92.0393 24.8326 92.0393 22.0617C92.0393 19.2609 90.2806 17.3836 87.768 17.3836C85.2554 17.3836 83.4957 19.2609 83.4957 22.0617C83.4957 24.8016 85.2535 26.7099 87.768 26.7099Z'
					fill='#283C55'
				/>
				<path
					d='M97.3413 25.5093C97.3413 22.4625 99.4774 20.8311 104.283 20.3384L107.361 20.0305V19.6916C107.361 18.0612 106.167 17.0757 104.377 17.0757C102.712 17.0757 101.582 17.9683 101.362 19.5687H97.4342C97.8749 16.4909 100.513 14.0598 104.376 14.0598C108.46 14.0598 111.349 16.245 111.349 20.2154V29.8176H107.36V27.8465C106.669 29.0771 104.847 30.0626 102.837 30.0626C99.5091 30.0645 97.3413 28.1863 97.3413 25.5093ZM103.623 27.0787C105.916 27.0787 107.359 25.2953 107.359 23.0773V22.8314L104.438 23.2003C102.235 23.4471 101.266 24.0629 101.266 25.3591C101.268 26.432 102.179 27.0787 103.623 27.0787Z'
					fill='#283C55'
				/>
				<path
					d='M113.422 14.3057H117.412V16.3989C118.292 14.9834 120.144 14.0598 122.061 14.0598C122.72 14.0598 123.317 14.1537 123.82 14.3677V18.0612C123.138 17.8073 122.413 17.6819 121.683 17.6914C119.39 17.6914 117.412 19.5996 117.412 23.939V29.8176H113.422V14.3057Z'
					fill='#283C55'
				/>
				<path
					d='M125.107 25.5093C125.107 22.4625 127.243 20.8311 132.048 20.3384L135.127 20.0305V19.6916C135.127 18.0612 133.933 17.0757 132.143 17.0757C130.478 17.0757 129.347 17.9683 129.127 19.5687H125.2C125.64 16.4909 128.279 14.0598 132.142 14.0598C136.224 14.0598 139.114 16.245 139.114 20.2154V29.8176H135.126V27.8465C134.434 29.0771 132.613 30.0626 130.603 30.0626C127.274 30.0645 125.107 28.1863 125.107 25.5093ZM131.389 27.0787C133.681 27.0787 135.125 25.2953 135.125 23.0773V22.8314L132.203 23.2003C130 23.4471 129.031 24.0629 129.031 25.3591C129.03 26.432 129.944 27.0787 131.389 27.0787Z'
					fill='#283C55'
				/>
				<path
					d='M142.571 24.6477V17.4765H140.026V14.3058H142.571V10.5203H146.559V14.3058H150.295V17.4765H146.559V24.3107C146.559 25.8801 147.376 26.6808 148.695 26.6808C149.283 26.6708 149.866 26.5668 150.42 26.3729V29.6609C149.7 29.9382 148.932 30.0743 148.159 30.0617C144.958 30.0646 142.571 28.1591 142.571 24.6477Z'
					fill='#283C55'
				/>
				<path
					d='M9.47656 5.71443C9.47644 5.52679 9.51406 5.34097 9.58728 5.16758C9.66051 4.9942 9.76789 4.83666 9.9033 4.70398C10.0387 4.5713 10.1995 4.46607 10.3764 4.39432C10.5534 4.32257 10.743 4.2857 10.9345 4.28583H15.3083C15.6949 4.28583 16.0658 4.43634 16.3392 4.70426C16.6126 4.97217 16.7662 5.33554 16.7662 5.71443C16.7662 6.09332 16.6126 6.45669 16.3392 6.72461C16.0658 6.99253 15.6949 7.14304 15.3083 7.14304H10.9345C10.5478 7.14304 10.177 6.99253 9.90358 6.72461C9.63017 6.45669 9.47656 6.09332 9.47656 5.71443Z'
					fill='#283C55'
				/>
				<path
					d='M13.1233 31.4284C12.6908 31.4284 12.268 31.5541 11.9083 31.7895C11.5487 32.025 11.2684 32.3597 11.1029 32.7512C10.9373 33.1428 10.894 33.5737 10.9784 33.9893C11.0628 34.405 11.2711 34.7869 11.5769 35.0865C11.8828 35.3862 12.2724 35.5903 12.6967 35.673C13.1209 35.7557 13.5606 35.7133 13.9602 35.5511C14.3598 35.3889 14.7013 35.1142 14.9416 34.7618C15.1819 34.4094 15.3102 33.9951 15.3102 33.5713C15.3102 33.0029 15.0798 32.4579 14.6697 32.056C14.2595 31.6541 13.7033 31.4284 13.1233 31.4284Z'
					fill='#283C55'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M4.37379 2.57055e-06C3.21379 2.57055e-06 2.1013 0.451543 1.28105 1.25529C0.460809 2.05903 0 3.14915 0 4.28582L0 35.7142C0 36.8509 0.460809 37.941 1.28105 38.7447C2.1013 39.5485 3.21379 40 4.37379 40H21.8689C22.4437 40.0006 23.0129 39.8902 23.5441 39.6751C24.0752 39.46 24.5579 39.1444 24.9644 38.7463C25.371 38.3483 25.6935 37.8756 25.9135 37.3553C26.1335 36.835 26.2467 36.2774 26.2466 35.7142V4.28582C26.2467 3.72265 26.1335 3.16497 25.9135 2.64469C25.6935 2.12441 25.371 1.65173 24.9644 1.25368C24.5579 0.855637 24.0752 0.540029 23.5441 0.324911C23.0129 0.109793 22.4437 -0.00061428 21.8689 2.57055e-06H4.37379ZM2.91586 4.28582C2.91573 4.09818 2.95336 3.91235 3.02658 3.73897C3.0998 3.56559 3.20719 3.40805 3.3426 3.27537C3.478 3.14268 3.63877 3.03746 3.81571 2.96571C3.99266 2.89396 4.1823 2.85709 4.37379 2.85721H21.8689C22.2556 2.85721 22.6264 3.00773 22.8999 3.27564C23.1733 3.54356 23.3269 3.90693 23.3269 4.28582V35.7142C23.3269 35.9018 23.2892 36.0876 23.2159 36.2609C23.1426 36.4342 23.0352 36.5917 22.8999 36.7244C22.7645 36.857 22.6038 36.9622 22.4269 37.034C22.25 37.1058 22.0604 37.1428 21.8689 37.1428H4.37379C4.1823 37.1429 3.99266 37.106 3.81571 37.0343C3.63877 36.9625 3.478 36.8573 3.3426 36.7246C3.20719 36.592 3.0998 36.4344 3.02658 36.261C2.95336 36.0877 2.91573 35.9018 2.91586 35.7142V4.28582Z'
					fill='#283C55'
				/>
			</g>
			<defs>
				<clipPath id='clip0_281_27516'>
					<rect width='150.423' height='40' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
