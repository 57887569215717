import { useService } from '@visiba-cortex/instantiation';
import { FormEvent, Fragment, ReactNode, useMemo } from 'react';
import { HomeController } from './home.controller';
import { usePresentation } from '@visiba-cortex/presentation';
import { Box, Button, DialogModal, Flex, InputText, NotificationAlert, SafeArea, Spacer, Spinner, Stack, Text } from '@cellula/react';
import { CustomerAppListItem } from '@routes/home/home_customer_app_list_item.component';
import { tokens } from '@cellula/react-theme-patient';
import { AppListingApiModel } from '@api/generated/models';
import { EmptyState } from '@components/empty_state.component';
import styled from '@emotion/styled';

interface Props {}

export function Home(_: Props): JSX.Element {
	const homeController = useService(HomeController);
	const presentation = usePresentation(homeController.presentation);

	function handleCreateNewAppSubmit(data: FormEvent): Promise<void> {
		return homeController.createNewApp(data);
	}

	function handleRemovedPinnedClick(appListingId: number, isGlobalPin: boolean): void {
		homeController.removePinned(appListingId, isGlobalPin);
	}

	function handlePinnedClick(appListingId: number, isGlobalPin: boolean): void {
		homeController.addPinned(appListingId, isGlobalPin);
	}

	function handleCloseAppListing(): void {
		homeController.fetch();
	}

	function handleDataInvalidation(): void {
		homeController.fetch();
	}

	return (
		<SafeArea maxWidth='1000px'>
			<Spacer vertical={tokens.spacing.x6} />
			<Box>
				<Headline text='Globally Pinned apps' />
				<Spacer vertical={tokens.spacing.x2} />
				<DeriveFetching fetching={presentation.fetching} fallback={<Fallback />}>
					<AlphabeticallySortedList
						isLocalPinnedSection={false}
						isGlobalPinnedSection={true}
						showChar={false}
						list={presentation.pinnedGlobalApps}
						onPinButtonClick={handleRemovedPinnedClick}
						onCloseAppListing={handleCloseAppListing}
						fallback="You haven't pinned any apps"
					/>
				</DeriveFetching>

				<Spacer vertical={tokens.spacing.x4} />

				<Headline text='Pinned apps' />
				<Spacer vertical={tokens.spacing.x2} />
				<DeriveFetching fetching={presentation.fetching} fallback={<Fallback />}>
					<AlphabeticallySortedList
						isLocalPinnedSection={true}
						isGlobalPinnedSection={false}
						showChar={false}
						list={presentation.pinnedLocalApps}
						onPinButtonClick={handleRemovedPinnedClick}
						onCloseAppListing={handleCloseAppListing}
						fallback="You haven't pinned any apps"
					/>
				</DeriveFetching>

				<Spacer vertical={tokens.spacing.x4} />

				<Headline text='All apps'>
					<DialogModal
						withFormEncapsulation
						header='Create new app'
						description=''
						abortText='cancel'
						successText='Create new app'
						onSuccess={handleCreateNewAppSubmit}
						trigger={
							<Button variant='secondary' size='medium' icon='plus' iconPosition='leading'>
								Create new app
							</Button>
						}
					>
						<Stack gap={tokens.spacing.x2}>
							<InputText
								name='name'
								placeholder='Enter listing name'
								label='Listing name'
								helperText='Note: this is not the same as "app name". The field is only for the list'
								required
							/>
							<InputText name='androidPackageId' placeholder='Enter package name' label='Android package name' />
							<InputText name='iosBundleId' placeholder='Enter bundle ID' label='iOS bundle ID' />
						</Stack>
					</DialogModal>
				</Headline>
				<Spacer vertical={tokens.spacing.x2} />
				<DeriveFetching fetching={presentation.fetching} fallback={<Fallback />}>
					<AlphabeticallySortedList
						list={presentation.apps}
						onPinButtonClick={handlePinnedClick}
						onCloseAppListing={handleCloseAppListing}
						fallback='✨ No more apps the be found ✨'
					/>
				</DeriveFetching>
			</Box>

			<Spacer vertical={tokens.spacing.x9} />
		</SafeArea>
	);
}

function Fallback(): JSX.Element {
	// TODO: Use skeleton later

	return (
		<StyledFixedMinHeight>
			<Box align='center' justify='center'>
				<Spinner size='large' />
			</Box>
		</StyledFixedMinHeight>
	);
}

function DeriveFetching({
	fetching,
	fallback,
	children,
}: {
	fetching: 'pending' | 'error' | 'complete';
	fallback: ReactNode;
	children?: ReactNode;
}): JSX.Element {
	if (fetching === 'error') {
		return <NotificationAlert variant='danger'>An error occurred, please try to refresh.</NotificationAlert>;
	}

	if (fetching === 'pending') {
		return <Fragment>{fallback}</Fragment>;
	}

	return <Fragment>{children}</Fragment>;
}

function Headline({ text, children }: { text: string; children?: ReactNode }): JSX.Element {
	return (
		<Box justify='space-between' direction='row' align='center'>
			<Text as='h1' variant='headingSmall'>
				{text}
			</Text>
			{children}
		</Box>
	);
}

const StyledFixedMinHeight = styled.div`
	min-height: 100px;
`;

interface AlphabeticallySortedListProps {
	isLocalPinnedSection?: boolean;
	isGlobalPinnedSection?: boolean;
	showChar?: boolean;
	list: AppListingApiModel[];
	onPinButtonClick(appListingId: number, isPinGlobal: boolean): void;
	onCloseAppListing(): void;
	fallback: ReactNode;
}

function AlphabeticallySortedList({
	list,
	onPinButtonClick,
	onCloseAppListing,
	fallback,
	isLocalPinnedSection,
	isGlobalPinnedSection,
	showChar = true,
}: AlphabeticallySortedListProps): JSX.Element {
	const derivedMap = useMemo(() => {
		const map = new Map<string, AppListingApiModel[]>();

		list
			.sort((a, b) => a.name.localeCompare(b.name))
			.forEach((item) => {
				let firstChar = item.name[0];
				if (!firstChar) {
					firstChar = '#';
				} else {
					firstChar = firstChar.toLocaleUpperCase();
				}

				const arr = map.get(firstChar);
				if (arr != null) {
					arr.push(item);
					map.set(firstChar, arr);
				} else {
					map.set(firstChar, [item]);
				}
			});

		return map;
	}, [list]);

	const derivedKeyList = useMemo(() => {
		return Array.from(derivedMap.keys());
	}, [derivedMap]);

	return (
		<StyledFixedMinHeight>
			{derivedMap.size === 0 ? (
				<EmptyState>{fallback}</EmptyState>
			) : (
				<Stack gap={isLocalPinnedSection || isGlobalPinnedSection ? tokens.spacing.x0_5 : tokens.spacing.x2}>
					{derivedKeyList.map((key) => (
						<Flex key={key}>
							<Stack gap={tokens.spacing.x0_5}>
								{showChar ? (
									<Text variant='labelRegular' color={tokens.color.content.muted}>
										{key}
									</Text>
								) : null}
								{derivedMap
									.get(key)
									?.map((app) => (
										<CustomerAppListItem
											key={app.id}
											comment={app.comment}
											appListingId={app.id}
											appName={app.name}
											appPublishedState={app.appPublishedState}
											appListingPlayStoreInfoApiModel={app.appListingPlayStoreInfoApiModel}
											isLocalPinned={!!isLocalPinnedSection}
											isGlobalPinned={!!isGlobalPinnedSection}
											onTogglePinned={onPinButtonClick}
											onClose={onCloseAppListing} 
										/>								
									)) ?? <NotificationAlert variant='danger'>Something went wrong when trying to list the apps</NotificationAlert>}
							</Stack>
						</Flex>
					))}
				</Stack>
			)}
		</StyledFixedMinHeight>
	);
}
