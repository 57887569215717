import { Transient, __registerMetaData } from '@visiba-cortex/instantiation';
import { HttpFileServer } from '@application/http_file_server.service';
import { EventEmitter, ParameterHandler } from '@visiba-cortex/std';
import { AliveFileWorker, StaleFileWorker } from './file_worker/mod';
import { EnvironmentService } from '@application/environment.service';
import { FileApiSortingInputModel, FileImageTypeApiModel } from '@api/generated/models';

type Worker = AliveFileWorker | StaleFileWorker;

/**
 * _Note: This is a Transient service._
 *
 * @example
 * ```tsx
 * function MyComponent(): JSX.Element {
 *   const fileManagerService = useService(FileManagerService);
 *
 *   function handleFileChange(event: ChangeEvent<HTMLInputElement>): void {
 *     if (event.target.files != null) {
 *       fileManagerService.assignWorker(event.target.files, 'endpoint/identity');
 *
 *       queueMicrotask(() => {
 *         for (const worker of fileManagerService.peakWorkerList()) {
 *           worker.giveIdentity();
 *         }
 *       });
 *     }
 *
 *     event.target.value = '';
 *   }
 *
 *   function handleClick(): void {
 *     queueMicrotask(() => {
 *       for (const worker of fileManagerService.peakWorkerList()) {
 *         worker.upload().then(() => {
 *           fileManagerService.exhaustUploadedWorkers();
 *         });
 *       }
 *     });
 *   }
 *
 *   return (
 *     <div>
 *       <input type='file' onChange={handleFileChange} multiple />
 *       <button onClick={handleClick} />
 *     </div>
 *   );
 * }
 * ```
 */
@Transient()
export class FileManagerService {
	public static getExtension(filename: string): string {
		const split = filename.split('.').pop();

		return split?.toLowerCase() ?? '';
	}

	public static getFileType(fileName: string): FileTypes {
		// Todo: Should this check file type?
		const extension = FileManagerService.getExtension(fileName);

		if (
			extension === 'png' ||
			extension === 'jpg' ||
			extension === 'gif' ||
			extension === 'webp' ||
			extension === 'jpeg' ||
			extension === 'tiff'
		) {
			return FileTypes.Image;
		}

		if (extension === 'doc' || extension === 'docx') {
			return FileTypes.Doc;
		}

		if (extension === 'pdf') {
			return FileTypes.Pdf;
		}

		if (extension === 'xlsx') {
			return FileTypes.Xslx;
		}

		return FileTypes.Unknown;
	}

	public static resolveMimeType(fileName: string): VisibaAcceptedMimeTypes {
		const extension = FileManagerService.getExtension(fileName);
		switch (extension) {
			case 'doc':
				return 'application/msword';
			case 'docx':
				return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
			default:
				return 'application/octet-stream';
		}
	}

	public static isAliveFileWorker(worker: Worker): worker is AliveFileWorker {
		if (worker instanceof AliveFileWorker) return true;

		return false;
	}

	public static isStaleFileWorker(worker: Worker): worker is StaleFileWorker {
		if (worker instanceof StaleFileWorker) return true;

		return false;
	}

	public readonly events = new EventEmitter<{
		onFileWorkerUpdate: Readonly<Worker[]>;
	}>();

	private workers: Worker[] = [];

	constructor(private readonly httpFileServer: HttpFileServer, private readonly environmentService: EnvironmentService) {
		// Empty
	}

	/**
	 * Assign a Worker to leverage the file uploading. This method only registers
	 * that a Worker is assigned to the file manager. The actual uploading process
	 * is managed by the Worker internally.
	 *
	 * @param file File or FileList to upload.
	 * @param identityEndpointPath An endpoint path to retrieve the file's
	 * identity. It's not static since different places may use different endpoints.
	 */
	public assignWorker(file: File, appId: number, type: FileImageTypeApiModel): void;
	public assignWorker(file: FileList, appId: number, type: FileImageTypeApiModel): void;
	public assignWorker(file: File | FileList, appId: number, type: FileImageTypeApiModel): void {
		let processingFiles: File[];
		if (file instanceof FileList) {
			processingFiles = Array.from(file);
		} else {
			processingFiles = [file];
		}

		// This order logic is kinda weird...
		let i = this.peakWorkerList().length;
		for (const file of processingFiles) {
			const params = new ParameterHandler();
			params.append({
				sortOrder: ++i,
			});
			const baseEndpointPath = this.environmentService.apiBaseUri + `/file/${appId}/${type}${params.getString()}`;
			const fileWorker = new AliveFileWorker(file, baseEndpointPath, this.httpFileServer);
			this.workers.push(fileWorker);
		}

		this.events.emit('onFileWorkerUpdate', this.workers);
	}

	public addStale(referenceId: string, appId: number): void {
		const params = new ParameterHandler();
		params.append({
			fileReference: referenceId,
			appDataId: appId,
		});
		const baseEndpointPath = this.environmentService.apiBaseUri + `/file/${params.getString()}`;

		this.workers.push(new StaleFileWorker(referenceId, baseEndpointPath));

		this.events.emit('onFileWorkerUpdate', this.workers);
	}

	/**
	 * Drops a worker from the list of workers. Will cancel the uploading process
	 * if ongoing.
	 *
	 * @param id a file worker's identity.
	 */
	public dropWorker(id: string): void {
		const index = this.workers.findIndex((worker) => worker.id === id);
		if (index >= 0) {
			const worker = this.workers.splice(index, 1)[0];
			if (FileManagerService.isAliveFileWorker(worker)) {
				worker.drop();
			}

			this.events.emit('onFileWorkerUpdate', this.workers);
		}
	}

	public dropAllWorkers(): void {
		this.workers = [];
		this.workers.forEach((worker) => {
			if (FileManagerService.isAliveFileWorker(worker)) {
				worker.drop();
			}
		});

		this.events.emit('onFileWorkerUpdate', this.workers);
	}

	public peakWorkerList(): Readonly<Worker[]> {
		return this.workers;
	}

	public peakAliveWorkerList(): Readonly<AliveFileWorker[]> {
		return this.workers.filter((worker) => FileManagerService.isAliveFileWorker(worker)) as AliveFileWorker[];
	}

	/**
	 * Returns a list of all the workers that has not been dropped.
	 */
	public peakUploadedWorkers(): Readonly<Worker[]> {
		const completed: Worker[] = [];

		for (const worker of this.workers) {
			if (FileManagerService.isStaleFileWorker(worker)) {
				completed.push(worker);
				continue;
			}

			if (worker.isUploaded()) {
				completed.push(worker);
				continue;
			}
		}

		return completed;
	}

	/**
	 * Returns List of the uploaded identifier's id.
	 */
	public flushUploadedIdentities(): string[] {
		const completed: string[] = [];
		const uncompleted: Worker[] = [];
		for (const worker of this.workers) {
			let id: string | null;
			if (FileManagerService.isStaleFileWorker(worker)) {
				id = worker.id;
			} else {
				id = worker.isUploaded(true);
			}
			if (id != null) {
				completed.push(id);
				continue;
			}

			uncompleted.push(worker);
		}

		this.workers = uncompleted;
		if (completed.length > 0) {
			this.events.emit('onFileWorkerUpdate', this.workers);
		}

		return completed;
	}

	public reorderWorkers(newOrder: AliveFileWorker[]): Worker[] {
		this.workers = newOrder;

		this.events.emit('onFileWorkerUpdate', this.workers);

		return this.workers;
	}

	public commitNewOrderedWorkers(appId: number, imageType: FileImageTypeApiModel): void {
		const allUploadedWorkers = this.peakUploadedWorkers();

		if (allUploadedWorkers.length === 0) return;

		const remapIntoSortingInputModel: FileApiSortingInputModel[] = allUploadedWorkers.map((worker, i) => ({
			imageType,
			referenceId: worker.referenceId,
			sortOrder: i + 1,
		}));

		this.httpFileServer.updateSortOrder(appId, remapIntoSortingInputModel);
	}
}
/* A vite plugin will eventually generate this */ __registerMetaData(FileManagerService, [HttpFileServer, EnvironmentService]);

export enum FileTypes {
	Image,
	Doc,
	Pdf,
	Xslx,
	Unknown,
}

type VisibaAcceptedMimeTypes =
	| 'application/msword'
	| 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
	| 'application/octet-stream';
