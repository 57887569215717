import { UserModel } from '@api/generated/models';
import { AuthService } from '@application/auth/auth.service';
import { useService } from '@visiba-cortex/instantiation';
import { useEffect, useState } from 'react';

export function useUser(): [isAuthenticated: boolean, user: UserModel | null] {
	const authService = useService(AuthService);
	const [user, setUser] = useState<Readonly<UserModel> | null>(authService.getUser());

	useEffect(() => {
		const [subscriber, user] = authService.provideSubscription();
		setUser(user);

		return subscriber((user) => {
			setUser(user);
		});
	}, [authService]);

	const isAuthenticated = user != null;

	return [isAuthenticated, user];
}
