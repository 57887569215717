import { AppPublishedState } from '@api/generated/models';
import { BadgeText } from '@cellula/react';
import type { ComponentProps } from 'react';

interface PublishedBadgeProps {
	appPublishedState: AppPublishedState;
}

export function PublishedBadge({ appPublishedState }: PublishedBadgeProps): JSX.Element {
	let variant: ComponentProps<typeof BadgeText>['variant'];
	switch (appPublishedState) {
		case AppPublishedState.Published: {
			variant = 'success';
			break;
		}
		case AppPublishedState.Internal:
		case AppPublishedState.Unknown: {
			variant = 'warning';
			break;
		}
		default: {
			variant = 'brandLight';
		}
	}

	return (
		<BadgeText variant={variant} size='small'>
			{appPublishedState}
		</BadgeText>
	);
}
