import { Scoped, __registerMetaData } from '@visiba-cortex/instantiation';
import { Presentation } from '@visiba-cortex/presentation';
import { NotificationService } from '@application/notification/notification.service';
import { AppBuildApiService } from '@api/appbuild_api_service';
import { AppPlayStoreDataApiService } from '@api/app_playstore_data_api.service';
import { AppPlayStoreDataApiModel } from '@api/generated/models';

interface Presentation {
	jsonVerified: boolean | null;
	invalidatedPlayStoreData: boolean | null,
}

@Scoped()
export class DevController {
	public readonly presentation = Presentation.create<Presentation>({
		jsonVerified: null,
		invalidatedPlayStoreData: null,
	});

	constructor(private readonly appListingApiService: AppBuildApiService, private readonly appPlayStoreDataApiService: AppPlayStoreDataApiService) {}

	public async verifyPlayStoreJsonFile(apiKey: string, json: string): Promise<void> {
		try {
			const isValid = await Promise.resolve(this.appListingApiService.verifyPlayStoreJsonFile(apiKey, json));

			this.presentation.write({
				jsonVerified: isValid,
			});
		} catch (error: unknown) {
			this.presentation.write({
				jsonVerified: null,
			});
		}
	}

	public async invalidatePlayStoreData(apiKey: string): Promise<void> {
		try {
			const isValid = await Promise.resolve(this.appListingApiService.invalidatePlayStoreData(apiKey));

			this.presentation.write({
				invalidatedPlayStoreData: isValid,
			});
		} catch (error: unknown) {
			this.presentation.write({
				invalidatedPlayStoreData: null,
			});
		}
	}

	public async savePlayStoreData(androidPackageId: string, jsonPlayStoreApiAccess: string): Promise<void> {
		const apiModel: AppPlayStoreDataApiModel = { jsonPlayStoreApiAccess: jsonPlayStoreApiAccess };

		await Promise.resolve(this.appPlayStoreDataApiService.save(androidPackageId, apiModel));
	}
}
/* A vite plugin will eventually generate this */ __registerMetaData(DevController, [AppBuildApiService, AppPlayStoreDataApiService, NotificationService]);
