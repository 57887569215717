import { LayoutProvider, CellulaProvider, TooltipProvider, CellulaI18nProvider } from '@cellula/react';
import { theme } from '@cellula/react-theme-patient';
import { NotificationProvider } from './notification_provider/mod';
import { Routes } from './routes.component';
import { AuthService, useUser } from '@application/auth/mod';
import { useService } from '@visiba-cortex/instantiation';
import { useEffect, useState } from 'react';

interface Props {}

export function App(_: Props): JSX.Element {
	return (
		<LayoutProvider>
			<TooltipProvider>
				<CellulaProvider
					providedInRoot
					theme={theme}
					tokenOverrides={{
						colorPrimary100: '#FBF8F4',
						colorPrimary200: '#F2EDE9',
						colorPrimary300: '#E6E1DD',
						colorPrimary400: '#D6D2CE',
						colorPrimary500: '#BAB6B2',
						colorPrimary600: '#979492',
						colorPrimary700: '#716F6D',
						colorPrimary800: '#5A5957',
						colorPrimary900: '#4A4847',
						colorPrimary1000: '#313130',
						colorAccent100: '#F9F7FF',
						colorAccent200: '#F1EDFE',
						colorAccent300: '#E5DEFD',
						colorAccent400: '#D7CCFC',
						colorAccent500: '#BAADF9',
						colorAccent600: '#9486F5',
						colorAccent700: '#5A5BEF',
						colorAccent800: '#0E42EB',
						colorAccent900: '#1B36BE',
						colorAccent1000: '#212678',
					}}
				>
					<CellulaI18nProvider>
						<NotificationProvider>
							<SomeApp />
						</NotificationProvider>
					</CellulaI18nProvider>
				</CellulaProvider>
			</TooltipProvider>
		</LayoutProvider>
	);
}


function SomeApp() {
	const authService = useService(AuthService);
	const [canRender, setCanRender] = useState(false);

	useEffect(() => {
		authService.fetchUser().then(() => {
			setCanRender(true);
		});
	}, [authService]);

	if (!canRender) return null;

	return <Routes />;
}