import { NotificationAlert, NotificationAlertProps, Spacer } from '@cellula/react';
import { tokens } from '@cellula/react-theme-patient';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

type Props = Omit<NotificationAlertProps, 'closable'>;

export function RemovableNotificationAlert(props: Props): JSX.Element {
	const [isClosed, setIsClosed] = useState(false);

	function handleOnClose(): void {
		setIsClosed((x) => !x);
		props.onClose?.();
	}

	return (
		<AnimatePresence>
			{!isClosed ? (
				<motion.div
					initial={{
						height: 0,
						opacity: 0,
					}}
					animate={{
						height: 'auto',
						opacity: 1,
						transition: {
							duration: 0.3,
						},
					}}
					exit={{
						height: 0,
						opacity: 0,
						transition: {
							duration: 0.3,
						},
					}}
					style={{ overflow: 'hidden' }}
				>
					<NotificationAlert variant={props.variant} closable onClose={handleOnClose}>
						{props.children}
					</NotificationAlert>
					<Spacer vertical={tokens.spacing.x2} />
				</motion.div>
			) : null}
		</AnimatePresence>
	);
}
