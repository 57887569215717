export type ResolvedResultState = [string, 'success'] | [null, 'cancelled'] | [null, 'unknown'];

export enum WorkerTaskState {
	Pristine,
	Uploading,
	Uploaded,
	UploadFailed,
	UploadCancelled,
	Deleting,
	Deleted,
	DeletionFailed,
	Downloading,
	Downloaded,
	DownloadFailed,
	ValidationFailed,
}
