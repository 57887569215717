import { FileImageTypeApiModel } from '@api/generated/models';

export interface ImageMetaData {
	type: FileImageTypeApiModel;
	errorName: string;
	title: string;
	dimensions: { text: string; minWidth: number; minHeight: number; maxWidth: number; maxHeight: number };
	naming: string | null;
	pictureCount: {
		text: string;
		minPictureCount: number;
		maxPictureCount: number;
	};
	fileTypeText: string;
	validationRule: RegExp;
}

export const iconMeta: ImageMetaData[] = [
	{
		type: FileImageTypeApiModel.AppIcon,
		errorName: 'AppIcon',
		title: 'App Icon',
		dimensions: { text: '1024 x 1024 pixels', minWidth: 1024, minHeight: 1024, maxWidth: 1024, maxHeight: 1024 },
		naming: 'Icon.png',
		pictureCount: { minPictureCount: 1, maxPictureCount: 1, text: '1' },
		fileTypeText: 'PNG',
		validationRule: /Icon.png/,
	},
];

export const iOSMeta: ImageMetaData[] = [
	{
		type: FileImageTypeApiModel.IosIPhoneScreenShots,
		errorName: 'IosIPhoneScreenShots',
		title: '5.5 inch (iPhone 8 Plus)',
		dimensions: { text: '1242 x 2208 pixels', minWidth: 1242, minHeight: 2208, maxHeight: 2208, maxWidth: 1242 },
		naming: 'iPhone 8 Plus - 1@3x.jpg, iPhone 8 Plus - 2@3x.jpg',
		pictureCount: { minPictureCount: 2, maxPictureCount: 8, text: '1 - 10' },
		fileTypeText: 'JPG',
		validationRule: /iPhone 8 Plus - \d@\dx.jpg/,
	},
	{
		type: FileImageTypeApiModel.IosIPhoneProScreenShots,
		errorName: 'IosIPhoneProScreenShots',
		title: '6.5 inch (iPhone 11 Pro Max)',
		dimensions: { text: '(1242 x 2688) - (1284 x 2778) pixels', minWidth: 1242, minHeight: 2688, maxHeight: 2778, maxWidth: 1284 },
		naming: 'iPhone 11 Pro Max - 1@3x.jpg, iPhone 11 Pro Max - 2@3x.jpg',
		pictureCount: { minPictureCount: 2, maxPictureCount: 8, text: '1 - 10' },
		fileTypeText: 'JPG',
		validationRule: /iPhone 11 Pro Max - \d@\dx.jpg/,
	},
	{
		type: FileImageTypeApiModel.IosIpadScreenShots,
		errorName: 'IosIpadScreenShots',
		title: '12.9 inch (2nd gen)',
		dimensions: { text: '2048 x 2732 pixels', minWidth: 2048, minHeight: 2732, maxHeight: 2732, maxWidth: 2048 },
		naming: 'Ipad - 1@2x.jpg, Ipad - 2@2x.jpg',
		pictureCount: { minPictureCount: 2, maxPictureCount: 8, text: '1 - 10' },
		fileTypeText: 'JPG',
		validationRule: /Ipad - \d@\dx.jpg/,
	},
	{
		type: FileImageTypeApiModel.IosIpadProScreenShots,
		errorName: 'IosIpadProScreenShots',
		title: '12.9 inch (iPad Pro 3rd gen)',
		dimensions: { text: '2048 x 2732 pixels', minWidth: 2048, minHeight: 2732, maxHeight: 2732, maxWidth: 2048 },
		naming: 'ipadPro129 - 1@2x.jpg, ipadPro129 - 2@2x.jpg',
		pictureCount: { minPictureCount: 2, maxPictureCount: 8, text: '1 - 10' },
		fileTypeText: 'JPG',
		validationRule: /ipadPro129 - \d@\dx.jpg/,
	},
];

export const androidMeta: ImageMetaData[] = [
	{
		type: FileImageTypeApiModel.AndroidFeatureGraphic,
		errorName: 'AndroidFeatureGraphic',
		title: 'Feature image Google',
		dimensions: { text: '1024 x 500 pixels', minWidth: 1024, minHeight: 500, maxHeight: 500, maxWidth: 1024 },
		naming: 'Optional',
		pictureCount: { minPictureCount: 1, maxPictureCount: 1, text: '1' },
		fileTypeText: 'PNG or JPG (max 8mb)',
		validationRule: /.(png|jpg)/,
	},
	{
		type: FileImageTypeApiModel.AndroidPhoneScreenShots,
		errorName: 'AndroidPhoneScreenShots',
		title: 'Android phone',
		dimensions: {
			text: '16:9 or 9:16 aspect ratio, with each side between 320 px and 3,840 px (minimum 2 images)',
			minWidth: 320,
			minHeight: 320,
			maxWidth: 3840,
			maxHeight: 3840,
		},
		naming: 'Optional',
		pictureCount: { minPictureCount: 1, maxPictureCount: 10, text: '2 - 8' },
		fileTypeText: 'PNG or JPG (max 8mb)',
		validationRule: /.(png|jpg)/,
	},
	{
		type: FileImageTypeApiModel.AndroidTabletScreenShots,
		errorName: 'AndroidTabletScreenShots',
		title: 'Android tablet',
		dimensions: {
			text: '16:9 or 9:16 aspect ratio, with each side between 320 px and 3,840 px (minimum 2 images)',
			minWidth: 1080,
			minHeight: 1080,
			maxHeight: 3840,
			maxWidth: 3840,
		},
		naming: 'Optional',
		pictureCount: { minPictureCount: 1, maxPictureCount: 10, text: '2 - 8' },
		fileTypeText: 'PNG or JPG (max 8mb)',
		validationRule: /.(png|jpg)/,
	},
	{
		type: FileImageTypeApiModel.AndroidLargeTabletScreenShots,
		errorName: 'AndroidLargeTabletScreenShots',
		title: 'Android tablet large',
		dimensions: {
			text: '16:9 or 9:16 aspect ratio, with each side between 1,080 px and 7,680 px (minimum 2 images)',
			minWidth: 1080,
			minHeight: 1080,
			maxHeight: 7680,
			maxWidth: 7680,
		},
		naming: 'Optional',
		pictureCount: { minPictureCount: 1, maxPictureCount: 10, text: '2 - 8' },
		fileTypeText: 'PNG or JPG (max 8mb)',
		validationRule: /.(png|jpg)/,
	},
];
