import { FileApiModel } from '@api/generated/models';
import { Http, HttpOptions } from '@visiba-cortex/http';
import { Presentation } from '@visiba-cortex/presentation';
import { v4 } from 'uuid';
import { WorkerTaskState } from './common';

export class StaleFileWorker {
	public readonly presentation = Presentation.create({
		fileState: WorkerTaskState.Pristine,
	});
	public readonly id: string;

	constructor(public readonly referenceId: string, public readonly endpoint: string) {
		this.id = v4();
	}

	public getThumbnail(): Promise<string | null> {
		const option = new HttpOptions({
			requestConfig: {
				withCredentials: true,
			},
		});

		return Http.get<FileApiModel>(this.endpoint, option).then(({ data }) => {
			if (data.file === '') return null;
			if (data.file == null) return null;

			return 'data:image/jpg;base64, ' + data.file;
		});
	}

	/**
	 * Returns a binary state whether the file has been uploaded or not.
	 */
	public isUploaded(): boolean;
	/**
	 * Returns the files identity if the file has been uploaded, if not the return
	 * value will be null.
	 */
	public isUploaded(withReturnValue?: boolean): string | null;
	public isUploaded(withReturnValue?: boolean): boolean | string | null {
		if (!withReturnValue) {
			return true;
		}

		return this.referenceId;
	}
}
