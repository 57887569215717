import { Instantiation, Singleton, __registerMetaData } from '@visiba-cortex/instantiation';

// Should be added to the instantiation module.

@Singleton()
export class Injector {
	public resolve<T>(ctor: { new (...args: any[]): T }): T {
		return Instantiation.resolve(ctor);
	}
}
/* A vite plugin will eventually generate this */ __registerMetaData(Injector, []);
