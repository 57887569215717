import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useService } from '@visiba-cortex/instantiation';
import { AnimatePresence, motion } from 'framer-motion';
import { Timer } from '@visiba-cortex/std';
import { tokens } from '@cellula/react-theme-patient';
import { Text } from '@visiba-cortex/react-ui-std';
import { IconButton, Icon, Portal, restrictedZIndexes } from '@cellula/react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { LocalPushNotification, Variants } from '@application/notification/local_push_notification';
import { NotificationService } from '@application/notification/notification.service';

interface Props {}

const elements = {
	container: styled.div`
		position: fixed;
		right: 0;
		bottom: ${tokens.spacing.x2};
		z-index: ${restrictedZIndexes.DANGER};
		display: flex;
		flex-direction: column;
		pointer-events: none;
		width: 100%;

		${tokens.breakpointQuery.medium} {
			right: 20px;
			width: 448px;
		}
	`,
	list: styled.ol`
		all: unset;
	`,
	listItem: styled(motion.li)<{ variant: Variants }>`
		${({ variant }) => {
			switch (variant) {
				case 'danger': {
					return css`
						--_background: ${tokens.color.danger.bg};
						--_border: ${tokens.color.danger.border};
						--_color: ${tokens.color.danger.content};
						--_iconColor: ${tokens.color.danger.contentMuted};
					`;
				}
				case 'info':
					return css`
						--_background: ${tokens.color.info.bg};
						--_border: ${tokens.color.info.border};
						--_color: ${tokens.color.info.content};
						--_iconColor: ${tokens.color.info.contentMuted};
					`;
				case 'success': {
					return css`
						--_background: ${tokens.color.success.bg};
						--_border: ${tokens.color.success.border};
						--_color: ${tokens.color.success.content};
						--_iconColor: ${tokens.color.success.contentMuted};
					`;
				}
				case 'warning': {
					return css`
						--_background: ${tokens.color.warning.bg};
						--_border: ${tokens.color.warning.border};
						--_color: ${tokens.color.warning.content};
						--_iconColor: ${tokens.color.warning.contentMuted};
					`;
				}
			}
		}}

		margin: 10px;
		pointer-events: all;
		display: flex;
		background-color: var(--_background);
		border: 1px solid var(--_border);
		color: var(--_content);
		padding: ${tokens.spacing.x2};
		gap: ${tokens.spacing.x2};
		border-radius: ${tokens.borderRadius.medium};
		width: inherit;
		box-shadow: ${tokens.elevation.floating};
	`,
	text: styled.div`
		display: flex;
		flex: 1;
		flex-direction: column;
	`,
	iconWrapper: styled.div`
		color: var(--_iconColor);
	`,
};

export function LocalPushNotifications(_: Props): JSX.Element {
	const notificationService = useService(NotificationService);
	const [notifications, setNotifications] = useState<LocalPushNotification[]>([]);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const toastRef = useRef<HTMLLIElement>(null);
	const listRef = useRef<HTMLOListElement>(null);
	const closeTimerRef = useRef<Timer>(new Timer());
	const removeNotification = useCallback(
		(target: LocalPushNotification) => {
			notificationService.remove(target);
			setNotifications([...notificationService.get('localPush')]);

			const isFocusInToast = toastRef.current?.contains(document.activeElement);
			if (isFocusInToast && notificationService.count('localPush') > 0) listRef.current?.focus();
		},
		[notificationService],
	);

	useEffect(
		function toHandleServiceEvents() {
			function handleChange(_: LocalPushNotification): void {
				setNotifications([...notificationService.get('localPush')]);
			}

			notificationService.eventEmitter.on('onAddLocalPush', handleChange);
			notificationService.eventEmitter.on('onRemoveLocalPush', handleChange);

			return () => {
				notificationService.eventEmitter.off('onAddLocalPush', handleChange);
				notificationService.eventEmitter.off('onRemoveLocalPush', handleChange);
			};
		},
		[notificationService],
	);

	useEffect(function toHandleKeyboardShortcut() {
		function handleKeyDown(event: KeyboardEvent): void {
			const isHotkeyPressed = ['f8'].every((key) => event.code?.toLowerCase() === key.toLowerCase());
			if (isHotkeyPressed) listRef.current?.focus();
		}
		document.addEventListener('keydown', handleKeyDown);

		return () => document.removeEventListener('keydown', handleKeyDown);
	}, []);

	useEffect(
		function toHandleCloseTimer() {
			closeTimerRef.current.flush();

			return closeTimerRef.current.wait(() => {
				const localPushes = notificationService.get('localPush');
				const toBeRemoved = localPushes[0];

				if (toBeRemoved != null) {
					removeNotification(toBeRemoved);
				}
			}, 5 * 1000);
		},
		[notifications, notificationService, removeNotification],
	);

	function handleClose(target: LocalPushNotification): void {
		removeNotification(target);
	}

	return (
		<Portal>
			<elements.container ref={wrapperRef} role='region' aria-label='Notifications' tabIndex={-1}>
				{/**
				 * tabindex on the the list so that it can be focused when items are removed. we focus
				 * the list instead of the viewport so it announces number of items remaining.
				 */}
				<elements.list tabIndex={-1} ref={listRef}>
					<AnimatePresence initial={false}>
						{notifications.map((notification, index) => {
							return (
								<Toast
									key={notification.guid}
									ref={index === 0 ? toastRef : undefined}
									notification={notification}
									onClose={handleClose}
								/>
							);
						})}
					</AnimatePresence>
				</elements.list>
			</elements.container>
		</Portal>
	);
}

interface ToastProps {
	notification: LocalPushNotification;
	onClose: (target: LocalPushNotification) => void;
}

const Toast = forwardRef<HTMLLIElement, ToastProps>(function Toast({ notification, onClose }, forwardedRef): JSX.Element {
	function handleOnKeyDown(target: LocalPushNotification): (event: React.KeyboardEvent<HTMLLIElement>) => void {
		return (event) => {
			if (event.key !== 'Escape') return;

			if (!event.nativeEvent.defaultPrevented) {
				onClose(target);
			}
		};
	}

	function handleOnClick(target: LocalPushNotification): (event: React.MouseEvent<HTMLButtonElement>) => void {
		return (event) => {
			event.stopPropagation();

			onClose(target);
		};
	}

	return (
		<elements.listItem
			variant={notification.variant}
			role='status'
			aria-live='off'
			tabIndex={0}
			ref={forwardedRef}
			layout
			initial={{ opacity: 0, y: 50, scale: 1 }}
			animate={{ opacity: 1, y: 0, scale: 1 }}
			exit={{ opacity: 0, scale: 0.98, transition: { duration: 0.2 } }}
			transition={{
				type: 'spring',
				stiffness: 240,
				damping: 20,
			}}
			onKeyDown={handleOnKeyDown(notification)}
		>
			<elements.iconWrapper>
				<Icon type='infoCircle' />
			</elements.iconWrapper>

			<elements.text>
				<Text font={tokens.text.label.largeSemiBold}>{notification.label}</Text>
				{notification.message != null ? <Text font={tokens.text.label.regular}>{notification.message}</Text> : null}
			</elements.text>

			<IconButton variant={notification.variant} size='small' text={'close'} icon='x' onClick={handleOnClick(notification)} />
		</elements.listItem>
	);
});
