import { Router } from 'react-router';
import { useService } from '@visiba-cortex/instantiation';
import { BrowserHistory } from 'history';
import { RouterService } from '@application/router.service';
import { ReactNode, useLayoutEffect, useRef, useState } from 'react';

interface Props {
	basename?: string;
	children?: ReactNode;
	window?: Window;
}

// Implements https://github.com/ReactTraining/react-router/blob/dev/packages/react-router-dom/index.tsx#L95
/**
 * A <Router /> component to use in web browsers. Provides the cleanest URLs.
 */
export function VisibaBrowserRouter({ children, window, basename }: Props): JSX.Element {
	const routerService = useService(RouterService);

	const historyRef = useRef<BrowserHistory>();
	if (historyRef.current == null) {
		historyRef.current = routerService.createBrowserHistory({ window });
	}

	const history = historyRef.current;
	const [state, setState] = useState({
		location: history.location,
	});

	useLayoutEffect(() => history.listen(setState), [history]);

	return (
		<Router location={state.location} navigator={history} basename={basename}>
			{children}
		</Router>
	);
}
