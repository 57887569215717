import { FileApiModel } from '@api/generated/models';
import { HttpFileServer } from '@application/http_file_server.service';

export interface FileIdentity extends FileApiModel {}

export class IdentityResolver {
	private identity: FileIdentity | null = null;
	private isCancelled = false;

	constructor() {
		// Empty
	}

	public reset(): void {
		this.isCancelled = true;
		this.identity = null;
	}

	public getIdentity(): FileIdentity | null {
		return this.identity;
	}

	public async unwrapIdentity(promise: ReturnType<HttpFileServer['upload']>[0]): Promise<FileIdentity> {
		if (this.identity != null) {
			return this.identity;
		}

		// This request should be cancellable from backend.
		const { data } = await promise;

		if (this.isCancelled) {
			// TODO: DEV-13650 expose a type of error from @visiba-cortex/http matching this.
			throw {
				__CANCEL__: true,
			};
		}

		this.identity = data;

		return data;
	}
}
