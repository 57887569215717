import { Route, Routes as DomRoutes, Navigate, Outlet } from 'react-router-dom';
import { Home } from '@routes/home/mod';
import { NotFoundPage } from '@routes/not_found.component';
import { SignIn } from '@routes/sign_in/mod';
import { VisibaBrowserRouter } from './visiba_browser_router.component';
import { ProtectedRoute } from '@components/protected_route.component';
import { NavBar } from './navbar/navbar.component';
import { Dev } from '@routes/dev/dev.component';
import { Video } from '@routes/video/video.component';

interface Props {}

function WithNavBar() {
	return (
	  <div>
		<NavBar />
		{}
		<Outlet />
	  </div>
	);
  }
  

export function Routes(___: Props): JSX.Element {
	return (
		<VisibaBrowserRouter>
			<DomRoutes>
				<Route element={<WithNavBar />}>
					<Route path='/' element={<ProtectedRoute redirectPath='/sign-in' element={<Home />} />} />
					<Route path='/dev' element={<ProtectedRoute redirectPath='/sign-in' element={<Dev />} />} />
					<Route path='/sign-in' element={<SignIn />} />
					<Route path='*' element={<NotFoundPage />} />
				</Route>
				<Route path='/video/:videoname' element={<Video />} />
			</DomRoutes>
		</VisibaBrowserRouter>
	);
}