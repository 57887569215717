import { Navigate } from 'react-router-dom';
import { ReactNode } from 'react';
import { useUser } from '@application/auth/mod';

interface ProtectedRouteProps {
	element: ReactNode;
	redirectPath: string;
}

export function ProtectedRoute(props: ProtectedRouteProps): JSX.Element {
	const [isAuthenticated, _] = useUser();

	if (!isAuthenticated) return <Navigate to={props.redirectPath} replace />;

	return <>{props.element}</>;
}
