import { useState } from 'react';
import { useEffect } from 'react';
import { FileManagerService } from './file_manager.service';
import type { AliveFileWorker, StaleFileWorker } from './file_worker/mod';

export function useFileWorkerList(fileManager: FileManagerService): Readonly<(AliveFileWorker | StaleFileWorker)[]> {
	const [, forceUpdate] = useState([]);

	useEffect(() => {
		return fileManager.events.on('onFileWorkerUpdate', () => {
			forceUpdate([]);
		});
	}, [fileManager]);

	return fileManager.peakWorkerList();
}
