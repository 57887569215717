import { AppListingApiService } from '@api/applisting_api.service';
import { AppListingCreateApiModel, AppPublishedState } from '@api/generated/models';
import { Scoped, __registerMetaData } from '@visiba-cortex/instantiation';
import { Presentation } from '@visiba-cortex/presentation';
import { LocalPushNotification } from '@application/notification/local_push_notification';
import { NotificationService } from '@application/notification/notification.service';
import { delay } from '@visiba-cortex/std';
import type { AppListingApiModel } from '@api/generated/models';
import { FormEvent } from 'react';

interface Presentation {
	pinnedLocalApps: AppListingApiModel[];
	pinnedGlobalApps: AppListingApiModel[];
	apps: AppListingApiModel[];
	fetching: 'pending' | 'error' | 'complete';
}

@Scoped()
export class HomeController {
	public readonly presentation = Presentation.create<Presentation>({
		apps: [],
		pinnedLocalApps: [],
		pinnedGlobalApps: [],
		fetching: 'pending',
	});

	constructor(private readonly appListingApiService: AppListingApiService, private readonly notificationService: NotificationService) {
		this.fetch();
	}

	public async fetch(): Promise<void> {
		try {
			const [apps, pinnedLocalApps, pinnedGlobalApps] = await Promise.all([this.appListingApiService.get(), this.appListingApiService.getFavorites(false), 
				this.appListingApiService.getFavorites(true)]);

			this.presentation.write({
				apps: this.parseAppResponse(apps),
				pinnedLocalApps: pinnedLocalApps,
				pinnedGlobalApps: pinnedGlobalApps,
				fetching: 'complete',
			});
		} catch (error: unknown) {
			this.presentation.write({
				fetching: 'error',
			});
		}
	}

	public removePinned(appListingId: number, isGlobalPin: boolean): void {
		const { pinnedLocalApps, pinnedGlobalApps, apps } = this.presentation.read();

		const [newPinnedApps, newApps] = this.swapItemBetweenArrays(appListingId, isGlobalPin ? pinnedGlobalApps : pinnedLocalApps, apps);
		this.presentation.write({
			...(isGlobalPin ? { pinnedGlobalApps: newPinnedApps } : { pinnedLocalApps: newPinnedApps }),
			apps: newApps,
		});

		this.appListingApiService
			.removeFavorite(appListingId, isGlobalPin)
			.then(() => {
				// We've already moved them around.
			})
			.catch(() => {
				// Revert if failed.

				const { pinnedLocalApps, pinnedGlobalApps, apps } = this.presentation.read();
				const [newApps, newPinnedApps] = this.swapItemBetweenArrays(appListingId, apps, isGlobalPin ? pinnedGlobalApps : pinnedLocalApps);
				this.presentation.write({
					...(isGlobalPin ? { pinnedGlobalApps: newPinnedApps } : { pinnedLocalApps: newPinnedApps }),
					apps: newApps,
				});

				this.notificationService.add(
					new LocalPushNotification({
						label: 'Could not unpin the app, try again',
						type: 'toast',
						variant: 'danger',
					}),
				);
			});
	}

	public addPinned(appListingId: number, isGlobalPin: boolean): void {
		const { pinnedLocalApps, pinnedGlobalApps, apps } = this.presentation.read();

		const [newApps, newPinnedApps] = this.swapItemBetweenArrays(appListingId, apps, isGlobalPin ? pinnedGlobalApps : pinnedLocalApps);
		this.presentation.write({
			...(isGlobalPin ? { pinnedGlobalApps: newPinnedApps } : { pinnedLocalApps: newPinnedApps }),
			apps: newApps,
		});

		this.appListingApiService
			.addFavorite(appListingId, isGlobalPin)
			.then(() => {
				// We've already moved them around.
			})
			.catch(() => {
				// Revert if failed.

				const { pinnedLocalApps, pinnedGlobalApps, apps } = this.presentation.read();
				const [newPinnedApps, newApps] = this.swapItemBetweenArrays(appListingId, isGlobalPin ? pinnedGlobalApps : pinnedLocalApps, apps);
				this.presentation.write({
					...(isGlobalPin ? { pinnedGlobalApps: newPinnedApps } : { pinnedLocalApps: newPinnedApps }),
					apps: newApps,
				});

				this.notificationService.add(
					new LocalPushNotification({
						label: 'Could not pin the app, try again',
						type: 'toast',
						variant: 'danger',
					}),
				);
			});
	}

	public async createNewApp(formEvent: FormEvent): Promise<void> {
		const formData = new FormData(formEvent.target as HTMLFormElement);
		const data: AppListingCreateApiModel = {
			name: formData.get('name')?.toString() ?? '',
			androidPackageId: formData.get('androidPackageId')?.toString() || null,
			iosBundleId: formData.get('iosBundleId')?.toString() || null,
		};

		await this.appListingApiService.addNewApp(data);

		delay(200).then(() => {
			this.notificationService.add(
				new LocalPushNotification({
					label: `${data.name} has been added 👀`,
					type: 'toast',
					variant: 'success',
				}),
			);
		});

		this.appListingApiService
			.get()
			.then((apps) => {
				this.presentation.write({
					apps: this.parseAppResponse(apps),
				});
			})
			.catch(() => {
				this.notificationService.add(
					new LocalPushNotification({
						label: 'Error when re-syncing the list ',
						type: 'toast',
						variant: 'danger',
					}),
				);
			});
	}

	private parseAppResponse(apps: AppListingApiModel[]): AppListingApiModel[] {
		return apps.filter(
			(apps) => apps.appPublishedState === AppPublishedState.Published || apps.appPublishedState === AppPublishedState.Draft,
		);
	}

	private swapItemBetweenArrays<T extends { id: number }>(id: number, from: T[], to: T[]): [newFrom: T[], newTo: T[]] {
		const index = from.findIndex((item) => item.id === id);
		if (index === -1) return [from, to];

		const diff = from.splice(index, 1);

		return [[...from], [...to, ...diff]];
	}
}
/* A vite plugin will eventually generate this */ __registerMetaData(HomeController, [AppListingApiService, NotificationService]);
