import { AppPublishedState, FileImageTypeApiModel } from '@api/generated/models';
import { FileManagerService } from '@application/file_manager/file_manager.service';
import { AliveFileWorker, StaleFileWorker, WorkerTaskState } from '@application/file_manager/mod';
import { useFileWorkerList } from '@application/file_manager/use_file_worker_list';
import {
	Box,
	Button,
	DialogAlert,
	Divider,
	Flex,
	IconButton,
	ImperativeSheetRef,
	InputText,
	InputTextArea,
	SafeArea,
	Spinner,
	Stack,
	TabNavigation,
	Text,
} from '@cellula/react';
import { tokens } from '@cellula/react-theme-patient';
import { CharCountedInputText } from '@components/char_counted_input_text.component';
import { CharCountedTextArea } from '@components/char_counted_text_area.component';
import { EmptyState } from '@components/empty_state.component';
import { PublishedBadge } from '@components/published_badge.component';
import { RemovableNotificationAlert } from '@components/removable_notification_alert.component';
import { SuspendedPresentation } from '@components/suspended_presentation.component';
import styled from '@emotion/styled';
import { useService } from '@visiba-cortex/instantiation';
import { usePresentation } from '@visiba-cortex/presentation';
import { Timer } from '@visiba-cortex/std';
import { AnimatePresence, Reorder } from 'framer-motion';
import { ChangeEvent, FormEvent, Fragment, ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { ImageMetaData } from './home_customer_app_info_form_image_meta_data';
import { CustomerAppInfoController, CustomerAppInfoFormController } from './_home_customer_app_info_form.controller';

interface Props {
	appListingName: string;
	appId: number;
	sheetRef: RefObject<ImperativeSheetRef>;
	onClose(): void;
}

const ValidatedForm = styled.form`
	display: contents;
`;

export function HomeCustomerAppInfoForm({ appListingName, appId, sheetRef, onClose }: Props): JSX.Element {
	const customerAppController = useService(CustomerAppInfoFormController);

	useEffect(
		function whenMountedLoadDetails() {
			customerAppController.createCustomerAppInfo(appId, appListingName);
		},
		[customerAppController, appId, appListingName],
	);

	return (
		<SafeArea asChild maxWidth='872px'>
			<Box asChild>
				<Stack gap={tokens.spacing.x4}>
					<SuspendedPresentation
						controller={customerAppController}
						fallback={
							<Flex fluid='all' grow={1} justify='center' align='center'>
								<Spinner size='large' />
							</Flex>
						}
					>
						{({ customerAppInfo }) => {
							return <Form customerAppInfo={customerAppInfo} sheetRef={sheetRef} onClose={onClose} />;
						}}
					</SuspendedPresentation>
				</Stack>
			</Box>
		</SafeArea>
	);
}

interface FormProps {
	customerAppInfo: CustomerAppInfoController;
	sheetRef: RefObject<ImperativeSheetRef>;
	onClose(): void;
}

function Form({ customerAppInfo, sheetRef, onClose }: FormProps): JSX.Element {
	const presentation = usePresentation(customerAppInfo.presentation);
	const formRef = useRef<HTMLFormElement>(null);

	const handleSubmit = (event: FormEvent): void => {
		event.preventDefault();
		customerAppInfo.submit(event, sheetRef);
	};

	function handleDeleteClick(): Promise<void> {
		return customerAppInfo.delete().then(() => {
			// We need to fix animation for sheet within Cellula.
			sheetRef.current?.close();
			onClose();
		});
	}

	function handlePublishFromDraftClick(): void {
		if (formRef.current == null) return;

		customerAppInfo.publish(formRef.current, sheetRef);
	}

	function handleReset(): (type: FileImageTypeApiModel) => void {
		return (type: FileImageTypeApiModel) => {
			customerAppInfo.resetImages(type);
		};
	}

	function handleCancelClick(): void {
		if (
			(formRef.current != null && !customerAppInfo.hasChanges(formRef.current)) ||
			confirm('You have unsaved changes, are you sure you want to continue?')
		) {
			sheetRef.current?.close();

			return;
		}
	}

	return (
		<ValidatedForm onSubmit={handleSubmit} ref={formRef}>
			<SectionTitle
				left={
					<Fragment>
						<Text.h1 variant='headingMedium'>{customerAppInfo.appListingName || 'UNTITLED'}</Text.h1>
						<PublishedBadge appPublishedState={customerAppInfo.fetchedData.appPublishedState} />
					</Fragment>
				}
			/>

			<Divider decorative />

			<SectionTitle
				left={<Text.h2 variant='headingSmall'>General info</Text.h2>}
				right={
					customerAppInfo.fetchedData.appPublishedState === AppPublishedState.Published ? undefined : (
						<DialogAlert
							header='Delete app'
							description="Are you sure you want to delete the app? This action can't be undone."
							abortText='Cancel'
							onSuccess={handleDeleteClick}
							successText='Delete'
							trigger={
								<Button
									size='xSmall'
									variant='danger'
									disabled={presentation.waitingForResponse}
									disabledText='Waiting for backend response'
								>
									Delete App
								</Button>
							}
						/>
					)
				}
			/>
			<Flex fluid='horizontal' gap={tokens.spacing.x9}>
				<Flex
					fluid='horizontal'
					breakpointSmallAndUp={{
						direction: 'row',
						gap: tokens.spacing.x5,
					}}
					gap={tokens.spacing.x2}
				>
					<Flex fluid='horizontal' grow={1} gap={tokens.spacing.x2}>
						<CharCountedInputText
							maxChars={30}
							name='appName'
							required
							type='text'
							label='App name'
							helperText='Shown in Google Play Store/Apple App Store'
							defaultValue={customerAppInfo.fetchedData.appName ?? ''}
						/>
						<CharCountedInputText
							maxChars={30}
							name='appDisplayName'
							type='text'
							label='App Display Name'
							helperText={`Displayed on device home screen. Will be truncated if more than 12 characters`}
							invalid={presentation.validationResult?.appDisplayName.isValid === false}
							invalidText={presentation.validationResult?.appDisplayName.errorMessage}
							defaultValue={customerAppInfo.fetchedData.appDisplayName ?? ''}
						/>
						<CharCountedInputText
							maxChars={100}
							name='tagsJson'
							type='text'
							label='Tags/Keywords'
							helperText={'Separate tags/keywords by comma, no whitespaces is needed (e.g.: "healthcare,mental health")'}
							invalid={presentation.validationResult?.tagsJson.isValid === false}
							invalidText={presentation.validationResult?.tagsJson.errorMessage}
							defaultValue={customerAppInfo.fetchedData.tagsJson ?? ''}
						/>
						<InputText
							name='url'
							type='text'
							label='Website'
							helperText='URL to the company. Example of format: https://visibacare.com'
							invalid={presentation.validationResult?.url.isValid === false}
							invalidText={presentation.validationResult?.url.errorMessage}
							defaultValue={customerAppInfo.fetchedData.url ?? ''}
						/>
						<InputText
							name='email'
							type='text'
							label='Email'
							helperText='Email to the company/contact person (shown public in GooglePlay). Example of format email@visibacare.com'
							invalid={presentation.validationResult?.email.isValid === false}
							invalidText={presentation.validationResult?.email.errorMessage}
							defaultValue={customerAppInfo.fetchedData.email ?? ''}
						/>
					</Flex>
					<Flex fluid='horizontal' grow={1} gap={tokens.spacing.x2}>
						<Stack gap={tokens.spacing.x2}>
							<CharCountedTextArea
								maxChars={80}
								name='shortDescription'
								label='Short Description'
								helperText='Information about the company and the apps functionality.'
								minHeight={65}
								autoHeight
								invalid={presentation.validationResult?.shortDescription.isValid === false}
								invalidText={presentation.validationResult?.shortDescription.errorMessage}
								defaultValue={customerAppInfo.fetchedData.shortDescription ?? ''}
							/>
							<CharCountedTextArea
								maxChars={4000}
								name='longDescription'
								label='Long Description'
								helperText='More information about the company and the apps functionality.'
								minHeight={300}
								invalid={presentation.validationResult?.longDescription.isValid === false}
								invalidText={presentation.validationResult?.longDescription.errorMessage}
								defaultValue={customerAppInfo.fetchedData.longDescription ?? ''}
							/>
							<InputText
								name='availableCountryCodes'
								type='text'
								label='Country availability'
								helperText={'Separate Country (ISO Alpha-2) codes by comma (e.g.: "SE,NO,FI").'}
								invalid={presentation.validationResult?.availableCountryCodes.isValid === false}
								invalidText={presentation.validationResult?.availableCountryCodes.errorMessage}
								defaultValue={customerAppInfo.fetchedData.availableCountryCodes?.join(',') ?? ''}
							/>
							<a href="https://www.nationsonline.org/oneworld/country_code_list.htm" target="_blank">nationsonline.org - Country Codes List</a>
						</Stack>
					</Flex>
				</Flex>


			</Flex>

			<Divider decorative />

			<SectionTitle
				left={<Text.h2 variant='headingSmall'>Build specifications (filled by developer)</Text.h2>}
			/>
			<Flex
				fluid='horizontal'
				breakpointSmallAndUp={{
					direction: 'row',
					gap: tokens.spacing.x5,
				}}
				gap={tokens.spacing.x2}
			>
				<Flex fluid='horizontal' grow={1} gap={tokens.spacing.x2}>
					<InputText
						name='iosBundleId'
						type='text'
						label='iOS bundle id'
						invalid={presentation.validationResult?.iosBundleId.isValid === false}
						invalidText={presentation.validationResult?.iosBundleId.errorMessage}
						defaultValue={customerAppInfo.fetchedData.iosBundleId ?? ''}
					/>
				</Flex>
				<Flex fluid='horizontal' grow={1} gap={tokens.spacing.x2}>
					<InputText
						name='androidPackageId'
						type='text'
						label='Android package name'
						invalid={presentation.validationResult?.androidPackageId.isValid === false}
						invalidText={presentation.validationResult?.androidPackageId.errorMessage}
						defaultValue={customerAppInfo.fetchedData.androidPackageId ?? ''}
					/>
				</Flex>
				<InputText
					name='langCode'
					type='text'
					label='Language code (only main)'
					helperText={''}
					defaultValue={customerAppInfo.fetchedData.langCode ?? ''}
				/>
			</Flex>

			<Divider decorative />

			<Flex fluid='horizontal'>
				<Stack gap={tokens.spacing.x2}>
					{customerAppInfo.fileGroupingIcon.map((fileGroup) => (
						<ImageUpload
							invalid={presentation.validationResult?.images.has(fileGroup.meta.errorName) === true}
							key={fileGroup.meta.title}
							meta={fileGroup.meta}
							appId={customerAppInfo.fetchedData.id}
							fileManagerService={fileGroup.fileManager}
							onReset={handleReset()}
						/>
					))}
				</Stack>
			</Flex>

			<Divider decorative />

			<Box padding={tokens.spacing.x2_5} borderRadius={tokens.borderRadius.medium} background={tokens.color.warning.bg}>
				<InputTextArea
					name='actionNeeded'
					autoHeight
					type='text'
					label='Issue (internal note)'
					defaultValue={customerAppInfo.fetchedData.actionNeeded ?? ''}
				/>
			</Box>

			<Divider decorative />

			<Text variant='headingSmall'>Screenshots</Text>
			<TabNavigation.Root defaultValue='android'>
				<TabNavigation.Bar label='Screenshots for the different platforms' />
				<TabNavigation.Content label='Android' value='android'>
					<Stack gap={tokens.spacing.x2}>
						{customerAppInfo.fileGroupingAndroid.map((fileGroup) => (
							<ImageUpload
								invalid={presentation.validationResult?.images.has(fileGroup.meta.errorName) === true}
								key={fileGroup.meta.title}
								meta={fileGroup.meta}
								appId={customerAppInfo.fetchedData.id}
								fileManagerService={fileGroup.fileManager}
								onReset={handleReset()}
							/>
						))}
					</Stack>
				</TabNavigation.Content>
				<TabNavigation.Content label='iOS' value='ios'>
					<Stack gap={tokens.spacing.x2}>
						{customerAppInfo.fileGroupingIOS.map((fileGroup) => (
							<ImageUpload
								invalid={presentation.validationResult?.images.has(fileGroup.meta.errorName) === true}
								key={fileGroup.meta.title}
								meta={fileGroup.meta}
								appId={customerAppInfo.fetchedData.id}
								fileManagerService={fileGroup.fileManager}
								onReset={handleReset()}
							/>
						))}
					</Stack>
				</TabNavigation.Content>
			</TabNavigation.Root>

			<Divider decorative />

			<Flex fluid='horizontal' gap={tokens.spacing.x3}>
				{presentation.validationResult != null ? (
					<RemovableNotificationAlert variant='danger'>
						{/* TODO: Make this simpler and more intuitive for the user */}
						The app could not be{' '}
						{customerAppInfo.fetchedData.appPublishedState === AppPublishedState.Published ? 'published' : 'saved'}. Reason:
						<br />
						{Object.entries(presentation.validationResult).map(([validationErrorKey, validationErrorValue], index) => {
							if (validationErrorValue instanceof Set) {
								return (
									<Fragment key={index}>
										<Text variant='bodySemiBold'>
											{Array.from(validationErrorValue.values()).map((value) => (
												<Fragment key={value}>
													Missing image: {value}
													<br />
												</Fragment>
											))}
										</Text>
										<br />
									</Fragment>
								);
							}

							if (validationErrorValue.isValid) return null;

							return (
								<Fragment key={index}>
									<Text variant='bodySemiBold'>
										{validationErrorKey}: {validationErrorValue.errorMessage}
									</Text>
									<br />
								</Fragment>
							);
						})}
					</RemovableNotificationAlert>
				) : null}

				<Flex fluid='horizontal' direction='row' justify='space-between'>
					<Flex fluid='horizontal' direction='row' justify='flex-start'>
						<Button size='medium' variant='ghost' onClick={handleCancelClick}>
							Cancel
						</Button>
					</Flex>

					<Flex fluid='horizontal' direction='row' justify='flex-end' gap={tokens.spacing.x2}>
						{presentation.canSaveDraft ? (
							<Button
								type='submit'
								size='medium'
								variant='secondary'
								disabled={presentation.waitingForResponse}
								disabledText='Waiting for backend response'
							>
								Save
							</Button>
						) : (
							<Fragment>
								<Button
									type='submit'
									size='medium'
									variant='secondary'
									disabled={presentation.waitingForResponse}
									disabledText='Waiting for backend response'
								>
									Save draft
								</Button>
								<Button
									size='medium'
									variant='primary'
									onClick={handlePublishFromDraftClick}
									disabled={presentation.waitingForResponse}
									disabledText='Waiting for backend response'
								>
									Publish
								</Button>
							</Fragment>
						)}
					</Flex>
				</Flex>
			</Flex>
		</ValidatedForm>
	);
}

function SectionTitle({ left, right }: { left?: ReactNode; right?: ReactNode }): JSX.Element {
	return (
		<Flex fluid direction='row' align='center' justify='space-between'>
			<Flex direction='row' align='center' gap={tokens.spacing.x2}>
				{left}
			</Flex>
			<Flex direction='row' align='center' gap={tokens.spacing.x2}>
				{right}
			</Flex>
		</Flex>
	);
}

function ImageUploadRow({ field, value }: { field: string; value: string }): JSX.Element {
	return (
		<Flex direction='row' gap={tokens.spacing.x0_5} align='start'>
			<Text variant='labelSemiBold'>{field}:</Text>
			<Text variant='labelRegular' color={tokens.color.content.mutedBrand}>
				{value}
			</Text>
		</Flex>
	);
}

function Preview({ worker }: { worker: AliveFileWorker | StaleFileWorker }): JSX.Element {
	const [url, setUrl] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const promiseOrPrimitive = worker.getThumbnail();
		const showLoadingIndicator = new Timer();

		if (promiseOrPrimitive instanceof Promise) {
			const promise = promiseOrPrimitive;

			showLoadingIndicator.wait(() => {
				setLoading(true);
			}, 100);

			promise.then((base64Url) => {
				if (base64Url == null) return;

				setUrl(base64Url);
				setLoading(false);
				showLoadingIndicator.flush();
			});
		} else {
			setUrl(promiseOrPrimitive);
		}

		return () => {
			showLoadingIndicator.flush();
		};
	}, [worker]);

	return (
		<Flex
			style={{
				pointerEvents: 'none',
			}}
			fluid='horizontal'
			align='center'
		>
			{url != null ? <img src={url} alt='' /> : loading ? <Spinner size='large' /> : null}
		</Flex>
	);
}

interface ImageProps {
	worker: AliveFileWorker | StaleFileWorker;
	onRemove(id: string): void;
	onDragEnd(id: string): void;
}

const StyledImageContent = styled.div`
	max-width: 300px;
	min-height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`;

const StyledImage = styled(Reorder.Item)`
	display: flex;
	flex-direction: column;
	width: 300px;
	flex-shrink: 0;
	gap: ${tokens.spacing.x1};
	padding: ${tokens.spacing.x1};
	border: 1px solid ${tokens.color.border.default};
	border-radius: ${tokens.borderRadius.medium};
	background-color: ${tokens.color.bg.surface};
`;

const StyledImageValidationFailedOverlay = styled.div`
	position: absolute;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(10px);
	color: #fff;

	&:before {
		content: '';
		position: absolute;
		inset: 0;
		background-color: #000;
		opacity: 0.5;
		z-index: -1;
	}
`;

function Image({ worker, onRemove, onDragEnd }: ImageProps): JSX.Element {
	const presentation = usePresentation(worker.presentation);

	function handleClick(): void {
		onRemove(worker.id);
	}

	function handleDragEnd(): void {
		onDragEnd(worker.id);
	}

	return (
		<StyledImage as='li' value={worker} onDragEnd={handleDragEnd}>
			<Flex justify='space-between' direction='row'>
				<IconButton size='small' text='Remove image' icon='bin' variant='danger' onClick={handleClick} />
			</Flex>

			<StyledImageContent>
				{(() => {
					switch (presentation.fileState) {
						case WorkerTaskState.Uploaded:
						case WorkerTaskState.Pristine:
							return <Preview worker={worker} />;
						case WorkerTaskState.UploadFailed:
							return (
								<Fragment>
									<StyledImageValidationFailedOverlay>
										<Text variant='labelLargeSemiBold'>Upload failed</Text>
									</StyledImageValidationFailedOverlay>
									<Preview worker={worker} />
								</Fragment>
							);
						case WorkerTaskState.ValidationFailed:
							return (
								<Fragment>
									<StyledImageValidationFailedOverlay>
										<Text variant='labelLargeSemiBold'>validation failed</Text>
									</StyledImageValidationFailedOverlay>
									<Preview worker={worker} />
								</Fragment>
							);
						default:
							return <Spinner size='medium' />;
					}
				})()}
			</StyledImageContent>
		</StyledImage>
	);
}

const StyledReorder = styled(Reorder.Group)`
	display: flex;
	padding: 0;
	overflow-x: auto;
	gap: ${tokens.spacing.x1};
`;

function ImageUpload({
	fileManagerService,
	appId,
	meta,
	invalid,
	onReset,
}: {
	fileManagerService: FileManagerService;
	appId: number;
	meta: ImageMetaData;
	invalid: boolean;
	onReset(type: FileImageTypeApiModel): void;
}): JSX.Element {
	const hiddenFileInput = useRef<HTMLInputElement>(null);
	const fileWorkers = useFileWorkerList(fileManagerService);

	function handleFileChange(event: ChangeEvent<HTMLInputElement>): void {
		if (event.target.files != null) {
			fileManagerService.assignWorker(event.target.files, appId, meta.type);
		}

		queueMicrotask(async () => {
			const workers = fileManagerService.peakAliveWorkerList();
			const promises = [];
			for (const worker of workers) {
				promises.push(worker.upload(meta['dimensions']));
			}

			await Promise.allSettled(promises)
				.then(() => {
					// noop - log to client logger
				})
				.catch(() => {
					// noop - log to client logger
				});
		});

		event.target.value = '';
	}

	function handleUploadFileButtonClick(): void {
		hiddenFileInput.current?.click();
	}

	function handleResetClick(): void {
		onReset(meta.type);
	}

	function handleReorder(commitOrder: AliveFileWorker[]): void {
		fileManagerService.reorderWorkers(commitOrder);
	}

	function handleOnDragEnd(): void {
		fileManagerService.commitNewOrderedWorkers(appId, meta.type);
	}

	function handleRemove(id: string): void {
		fileManagerService.dropWorker(id);
	}

	return (
		<div
			style={
				invalid
					? {
						outline: `1px solid ${tokens.color.danger.border}`,
						borderRadius: tokens.borderRadius.medium,
					}
					: undefined
			}
		>
			<Box
				data-invalid
				padding={tokens.spacing.x2_5}
				borderRadius={tokens.borderRadius.medium}
				background={tokens.color.bg.surfaceBrand}
				gap={tokens.spacing.x2}
			>
				<input type='file' ref={hiddenFileInput} onChange={handleFileChange} multiple hidden tabIndex={0} />

				<Flex direction='row' justify='space-between' fluid='horizontal'>
					<Flex basis='50%' gap={tokens.spacing.x0_5}>
						<Text variant='headingXSmall'>{meta.title}</Text>
						<ImageUploadRow field='Size' value={meta.dimensions.text} />
						<ImageUploadRow field='Naming' value={meta.naming ?? ''} />
						<ImageUploadRow field='Quantity' value={meta.pictureCount.text} />
						<ImageUploadRow field='File format' value={meta.fileTypeText} />
					</Flex>

					<Flex direction='row' gap={tokens.spacing.x2} align='start'>
						<Button variant='secondary' size='xSmall' icon='plus' iconPosition='leading' onClick={handleUploadFileButtonClick}>
							Add images
						</Button>
						<Button variant='danger' size='xSmall' onClick={handleResetClick}>
							Reset images
						</Button>
					</Flex>
				</Flex>

				{fileWorkers.length !== 0 ? (
					<StyledReorder as='ul' axis='x' values={fileWorkers as unknown as AliveFileWorker[]} onReorder={handleReorder}>
						<AnimatePresence initial={false}>
							{fileWorkers.map((item) => (
								<Image key={item.id} worker={item} onRemove={handleRemove} onDragEnd={handleOnDragEnd} />
							))}
						</AnimatePresence>
					</StyledReorder>
				) : (
					<EmptyState>No Images Uploaded yet</EmptyState>
				)}
			</Box>
		</div>
	);
}
