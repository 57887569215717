import { Box, SafeArea } from '@cellula/react';
import { Text } from '@visiba-cortex/react-ui-std';
import { Logo } from '@components/logo.component';
import { tokens } from '@cellula/react-theme-patient';

export function NavBar(): JSX.Element {
	return (
		<SafeArea maxWidth='1000px'>
			<Box direction='row' align='center' gap={tokens.spacing.x2} verticalPadding={tokens.spacing.x2}>
				<Logo />
				<Text font={tokens.text.label.xLargeRegular}>Visiba Care App delivery tool</Text>
			</Box>
		</SafeArea>
	);
}
