import { Transient, __registerMetaData } from '@visiba-cortex/instantiation';
import { HttpService, VisibaApiPromise } from '@application/http.service';
import { AppListingApiModel, AppListingCreateApiModel, AppListingCreateResultApiModel } from './generated/models';

@Transient()
export class AppListingApiService {
	constructor(private readonly httpService: HttpService) {
		// Empty
	}

	public get(count = 150): VisibaApiPromise<AppListingApiModel[]> {
		return this.httpService.get(`applisting?count=${count}`);
	}

	public getFavorites(isGlobalPin: boolean): VisibaApiPromise<AppListingApiModel[]> {
		return this.httpService.get(isGlobalPin ? 'applisting/favorites/global' : 'applisting/favorites');
	}

	public addFavorite(appListingId: number, isGlobalPin: boolean): VisibaApiPromise<boolean> {
		return this.httpService.put(isGlobalPin ? `applisting/favorites/global/${appListingId}` : `applisting/favorites/${appListingId}`, appListingId);
	}

	public removeFavorite(appListingId: number, isGlobalPin: boolean): VisibaApiPromise<boolean> {
		return this.httpService.delete(isGlobalPin ? `applisting/favorites/global/${appListingId}` : `applisting/favorites/${appListingId}`);
	}

	public addNewApp(data: AppListingCreateApiModel): VisibaApiPromise<AppListingCreateResultApiModel> {
		return this.httpService.post(`appListing`, data);
	}
}
/* A vite plugin will eventually generate this */ __registerMetaData(AppListingApiService, [HttpService]);
