import { Singleton } from '@visiba-cortex/instantiation';
import { Emitter } from '@visiba-cortex/std';
import { KnownApplicationNotificationTypes, ApplicationNotification } from './common_types';
import { ImplementRecordWithKnownKeys } from './common_types';
import type { LocalPushNotification } from './local_push_notification';

enum EventType {
	Add = 'add',
	Remove = 'remove',
}

type Events<T extends string> = `on${Capitalize<EventType>}${Capitalize<T>}`;

type MappedEvents = ImplementRecordWithKnownKeys<
	KnownApplicationNotificationTypes,
	{
		localPush: LocalPushNotification;
	}
>;

@Singleton()
export class NotificationService<
	T extends ImplementRecordWithKnownKeys<string, Record<string, ApplicationNotification<string>>> = MappedEvents,
> {
	public readonly eventEmitter = new Emitter<Events<Extract<keyof T, string>>>();
	private readonly storedNotifications = new Map<keyof T, T[keyof T][]>();

	constructor() {
		// Empty
	}

	public count<K extends keyof T>(notificationType: K): number {
		const list = this.get(notificationType);

		return list.length;
	}

	public add<K extends keyof T>(notification: T[K]): void {
		const storedNotifications = this.storedNotifications.get(notification.event);
		if (storedNotifications?.some((storedNotification) => storedNotification.guid === notification.guid)) return;

		this.storedNotifications.set(notification.event, [...(this.storedNotifications.get(notification.event) || []), notification]);
		this.eventEmitter.emit(this.convertTypeToEvent(notification.event, EventType.Add), notification);
	}

	public remove<K extends keyof T>(notification: T[K]): void {
		const notifications = this.storedNotifications.get(notification.event);
		if (notifications) {
			notifications.splice(notifications.indexOf(notification), 1);
			this.eventEmitter.emit(this.convertTypeToEvent(notification.event, EventType.Remove), notification);
		}
	}

	public isAdded<K extends keyof T>(notification: T[K]): boolean {
		const notifications = this.storedNotifications.get(notification.event);
		if (notifications == null) return false;

		return notifications.indexOf(notification) !== -1;
	}

	public get<K extends keyof T>(notificationType: K): T[K][] {
		return (this.storedNotifications.get(notificationType) as T[K][]) || [];
	}

	private convertTypeToEvent(str: string, eventType: EventType): Events<Extract<keyof T, string>> {
		function upperCase<K extends string | EventType>(str: K): `${Capitalize<K>}` {
			return (str.charAt(0).toUpperCase() + str.slice(1)) as `${Capitalize<K>}`;
		}

		return `on${upperCase(eventType)}${upperCase(str)}` as Events<Extract<keyof T, string>>;
	}
}
