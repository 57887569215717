import { Transient, __registerMetaData } from '@visiba-cortex/instantiation';
import { HttpService, VisibaApiPromise } from '@application/http.service';
import { AppPlayStoreDataApiModel } from '@api/generated/models';

@Transient()
export class AppPlayStoreDataApiService {
	constructor(private readonly httpService: HttpService) {
		// Empty
	}

	public get(androidPackageName: string): VisibaApiPromise<AppPlayStoreDataApiModel> {
		return this.httpService.get(`appplaystoredata/${androidPackageName}`);
	}

	public save(androidPackageName: string, appDataApiModel: AppPlayStoreDataApiModel): VisibaApiPromise<void> {
		return this.httpService.post(`appplaystoredata/${androidPackageName}`, appDataApiModel);
	}
}
/* A vite plugin will eventually generate this */ __registerMetaData(AppPlayStoreDataApiService, [HttpService]);
