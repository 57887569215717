import { Singleton, __registerMetaData } from '@visiba-cortex/instantiation';
import { Http, HttpResponse, HttpOptions, HttpOptionConfiguration } from '@visiba-cortex/http';
import { storage } from '@visiba-cortex/std';
import { EnvironmentService } from './environment.service';

export type VisibaApiPromise<T> = Promise<T>;

function interceptBeforeGoingToRequester<T>(response: HttpResponse<T>): T {
	return response.data;
}

export const devLocalStorage = storage<{
	identitySession: string | null;
	requestVerificationToken: string | null;
}>();

export class VisibaOptions extends HttpOptions {
	constructor({ cancelToken, requestConfig, ...rest }: HttpOptionConfiguration) {
		super({
			cancelToken,
			requestConfig: {
				withCredentials: true,
				headers: {
					'Content-Type': 'application/json',
					Pragma: 'no-cache',
					'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
					'Cache-Control': 'no-cache, no-store, must-revalidate',
				},
				...requestConfig,
			},
			...rest,
		});

		const customHeadersBuilder: Record<string, string | boolean> = {
			'X-App-Lang': '',
		};

		const requestVerificationToken = devLocalStorage.get('requestVerificationToken');
		const identitySession = devLocalStorage.get('identitySession');
		if (requestVerificationToken) {
			customHeadersBuilder['__RequestVerificationToken'] = requestVerificationToken;
		} else if (process.env.NODE_ENV === 'development' && identitySession) {
			customHeadersBuilder['X-Authorization'] = `ticket ${identitySession}`;
			customHeadersBuilder['X-App-SkipXsfrVerification'] = true;
		}

		this.requestConfig.headers = {
			...this.requestConfig.headers,
			...customHeadersBuilder,
		};
	}
}

@Singleton()
export class HttpService {
	private readonly http = Http.create('visiba');

	constructor(private readonly environmentService: EnvironmentService) {
		this.http.setCustomOption(VisibaOptions);
		this.http.setConfig({
			baseURL: this.environmentService.apiBaseUri,
		});
	}

	public get<T>(...args: ArgumentTypes<HttpService['http']['get']>): VisibaApiPromise<T> {
		return this.http.get<T>(...args).then((response) => interceptBeforeGoingToRequester(response));
	}

	public post<T>(...args: ArgumentTypes<HttpService['http']['post']>): VisibaApiPromise<T> {
		return this.http.post<T>(...args).then((response) => interceptBeforeGoingToRequester(response));
	}

	public put<T>(...args: ArgumentTypes<HttpService['http']['put']>): VisibaApiPromise<T> {
		return this.http.put<T>(...args).then((response) => interceptBeforeGoingToRequester(response));
	}

	public delete<T>(...args: ArgumentTypes<HttpService['http']['delete']>): VisibaApiPromise<T> {
		return this.http.delete<T>(...args).then((response) => interceptBeforeGoingToRequester(response));
	}
}
/* A vite plugin will eventually generate this */ __registerMetaData(HttpService, [EnvironmentService]);
