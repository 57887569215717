import { HttpFileServer } from '@application/http_file_server.service';
import { HttpCancelTokenSource } from '@visiba-cortex/http';
import { Presentation } from '@visiba-cortex/presentation';
import { v4 } from 'uuid';
import { ResolvedResultState, WorkerTaskState } from './common';
import { FileIdentity, IdentityResolver } from './_identity_resolver';
import { FileManagerService } from '../file_manager.service';
import { ImageMetaData } from '@routes/home/home_customer_app_info_form/home_customer_app_info_form_image_meta_data';

export class AliveFileWorker {
	public readonly presentation = Presentation.create({
		fileState: WorkerTaskState.Pristine,
	});
	public readonly id: string;
	public readonly fileName: string;
	public readonly fileType: string;
	public readonly fileSize: number;
	private previewImageSrc: string | null = null;
	private canCancelUpload: HttpCancelTokenSource | null = null;
	private identityResolver: IdentityResolver | null = null;
	private image: HTMLImageElement | null = null;
	private imagePromise: Promise<HTMLImageElement> | null = null;

	public get referenceId(): string {
		return this.getIdentity(true).referenceId;
	}

	constructor(private readonly file: File, private readonly endpoint: string, private readonly httpFileServer: HttpFileServer) {
		this.id = v4();
		this.fileName = file.name;
		this.fileSize = file.size;
		this.fileType = file.type === '' ? FileManagerService.resolveMimeType(file.name) : file.type;
	}

	/**
	 * If the worker is missing an identity, it'll try to resolve one for this worker.
	 */
	public async giveIdentity(): Promise<FileIdentity> {
		if (this.identityResolver == null) {
			this.identityResolver = new IdentityResolver();
		}

		const [request] = this.httpFileServer.upload(this.file, this.endpoint);

		return await this.identityResolver.unwrapIdentity(request);
	}

	/**
	 * Uploads the file to the server.
	 */
	public async upload(validation?: ImageMetaData['dimensions']): Promise<ResolvedResultState> {
		const identityTicket = this.isUploaded(true);
		if (identityTicket != null) {
			return [identityTicket, 'success'];
		}

		this.presentation.write({ fileState: WorkerTaskState.Uploading });

		const [request, cancel] = this.httpFileServer.upload(this.file, this.endpoint);

		let identity: FileIdentity;
		try {
			this.canCancelUpload = cancel;

			if (validation != null) {
				const image = await this.getImage(URL.createObjectURL(this.file));
				const width = image.naturalWidth;
				const height = image.naturalHeight;

				if (
					width < validation.minWidth ||
					width > validation.maxWidth ||
					height < validation.minHeight ||
					height > validation.maxHeight
				) {
					throw {
						__VALIDATION_FAILED__: true,
					};
				}
			}

			if (this.identityResolver != null) {
				identity = await this.identityResolver.unwrapIdentity(request);
			} else {
				identity = await this.giveIdentity();
			}

			this.presentation.write({ fileState: WorkerTaskState.Uploaded });
		} catch (error: unknown) {
			// TODO DEV-13650: expose a type of error from @visiba-cortex/http matching this.
			if ((error as { __CANCEL__: boolean }).__CANCEL__ === true) {
				this.presentation.write({ fileState: WorkerTaskState.UploadCancelled });
			} else if ((error as { __VALIDATION_FAILED__: boolean }).__VALIDATION_FAILED__ === true) {
				this.presentation.write({ fileState: WorkerTaskState.ValidationFailed });
			} else {
				this.presentation.write({ fileState: WorkerTaskState.UploadFailed });
			}

			return [null, 'cancelled'];
		} finally {
			this.canCancelUpload = null;
		}

		return [identity.referenceId, 'success'];
	}

	/**
	 * Returns a binary state whether the file has been uploaded or not.
	 */
	public isUploaded(): boolean;
	/**
	 * Returns the files identity if the file has been uploaded, if not the return
	 * value will be null.
	 */
	public isUploaded(withReturnValue?: boolean): string | null;
	public isUploaded(withReturnValue?: boolean): boolean | string | null {
		const identity = this.identityResolver?.getIdentity();
		const isUploaded = this.presentation.read().fileState === WorkerTaskState.Uploaded;

		if (!withReturnValue) {
			return identity != null && isUploaded;
		}

		if (identity == null) {
			return null;
		}

		return isUploaded ? identity.referenceId : null;
	}

	/**
	 * Cancels the upload if it is in progress. If the upload is completed, it'll
	 * try to remove the file.
	 */
	public async drop(): Promise<void> {
		this.removeThumbnail();

		const identity = this.identityResolver?.getIdentity();
		if (identity == null) {
			this.identityResolver?.reset();
			this.presentation.write({ fileState: WorkerTaskState.UploadCancelled });

			return;
		}

		if (this.canCancelUpload) {
			this.canCancelUpload.cancel();
			this.presentation.write({ fileState: WorkerTaskState.UploadCancelled });

			return;
		}

		const { fileState } = this.presentation.read();
		if (fileState === WorkerTaskState.Uploaded) {
			try {
				this.presentation.write({ fileState: WorkerTaskState.Deleting });
				// Not impl yet
				await this.httpFileServer.remove('');
				this.identityResolver = null;
				this.presentation.write({ fileState: WorkerTaskState.Deleted });
			} catch (error) {
				this.presentation.write({ fileState: WorkerTaskState.DeletionFailed });
			}
		}
	}

	/**
	 * Taps into the workers identity resolver to get the identity of the file, or
	 * null if the identity is not yet resolved.
	 *
	 * @returns FileIdentity | null
	 */
	public getIdentity(): FileIdentity | null;
	public getIdentity(x: true): FileIdentity;
	public getIdentity(x?: boolean): FileIdentity | null {
		const identity = this.identityResolver?.getIdentity() ?? null;

		if (!x) return identity;

		if (identity == null) throw new Error('Identity was not set');

		return identity;
	}

	/**
	 * Returns which state the file assigned to the worker is in.
	 *
	 * @returns WorkerTaskState
	 */
	public getFileTaskState(): WorkerTaskState {
		return this.presentation.read().fileState;
	}

	public async getImage(url: string): Promise<HTMLImageElement> {
		if (this.image != null) return this.image;

		if (this.imagePromise) {
			return await this.imagePromise;
		}

		const img = new Image();
		const promise = new Promise<HTMLImageElement>((resolve, reject) => {
			img.onload = () => {
				resolve(img);
			};

			img.onerror = reject;
		});
		this.imagePromise = promise;

		img.src = url;

		return await promise;
	}

	public getThumbnail(): string | null {
		if (this.previewImageSrc == null && this.fileType.includes('image')) {
			this.previewImageSrc = URL.createObjectURL(this.file);
		}

		return this.previewImageSrc;
	}

	public removeThumbnail(): void {
		if (this.previewImageSrc != null) {
			URL.revokeObjectURL(this.previewImageSrc);
			this.previewImageSrc = null;
			this.image = null;
			this.imagePromise = null;
		}
	}
}
