import { UserModel } from '@api/generated/models';
import { UserApiService } from '@api/user_api.service';
import { isHttpError } from '@visiba-cortex/http';
import { Singleton, __registerMetaData } from '@visiba-cortex/instantiation';
import { Presentation } from '@visiba-cortex/presentation';
import { Emitter } from '@visiba-cortex/std';
import { LocalPushNotification } from '../notification/local_push_notification';
import { NotificationService } from '../notification/notification.service';

interface Presentation {
	user: UserModel | null;
}
type Disposer = () => void;
type Subscriber = (fn: (userRef: Readonly<UserModel> | null) => void) => Disposer;

@Singleton()
export class AuthService {
	public events = new Emitter<'onSuccessfulAuthentication' | 'onAuthenticationTermination'>();
	private user: UserModel | null = null;

	constructor(private readonly userApiService: UserApiService, private readonly notificationService: NotificationService) {
		// Empty
	}

	public async fetchUser(): Promise<void> {
		try {
			const userData = await this.userApiService.getIdentity();

			this.user = userData;
			this.events.emit('onSuccessfulAuthentication', userData);
		} catch (error) {
			if (isHttpError(error)) {
				this.notificationService.add(
					new LocalPushNotification({
						label: 'Error getting user identity',
						type: 'toast',
						variant: 'danger',
					}),
				);

				return;
			}
		}
	}

	/**
	 * Returns, if successfully authenticated, the currently authenticated user.
	 * Otherwise null.
	 */
	public getUser(): Readonly<UserModel> | null {
		return this.user;
	}

	/**
	 * Creates a "subscription" to the event emitter's
	 * "onSuccessfulAuthentication" and "onAuthenticationTermination" events. It's
	 * use case is to provide a way to check the authentication status if time is
	 * an concern. For instance: in a controller that's expected a certain state
	 * when the user is authenticated, you may want to set the state with second
	 * value on initialization and use the subscriber to eventually patch the
	 * state when the authentication status changes.
	 */
	public provideSubscription(): [subscriber: Subscriber, user: Readonly<UserModel> | null] {
		const subscriber: Subscriber = (fn) => {
			const handleSubscriberEvent = (_: Readonly<UserModel> | null): void => {
				fn(this.getUser());
			};

			this.events.on('onSuccessfulAuthentication', handleSubscriberEvent);
			this.events.on('onAuthenticationTermination', handleSubscriberEvent);

			return () => {
				this.events.off('onSuccessfulAuthentication', handleSubscriberEvent);
				this.events.off('onAuthenticationTermination', handleSubscriberEvent);
			};
		};

		return [subscriber, this.getUser()];
	}

	public async terminateAuthentication(): Promise<void> {
		if (this.user == null) return;

		const user = this.user;

		this.user = null;

		this.events.emit('onAuthenticationTermination', user);
	}
}
/* A vite plugin will eventually generate this */ __registerMetaData(AuthService, [UserApiService, NotificationService]);
