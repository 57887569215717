import { useService } from '@visiba-cortex/instantiation';
import { usePresentation } from '@visiba-cortex/presentation';
import { Box, Button, DialogModal, InputText, InputTextArea, SafeArea, Spacer, Stack, TextArea } from '@cellula/react';
import { tokens } from '@cellula/react-theme-patient';
import { DevController } from './dev.controller';
import { useState } from 'react';

interface Props {}

export function Dev(_: Props): JSX.Element {
	const devController = useService(DevController);
	const presentation = usePresentation(devController.presentation);

	const [apiKey, setApiKey] = useState('');
	const [jsonVerify, setVerifyJson] = useState('');

	const [jsonSave, setSaveJson] = useState('');
	const [androidPackageId, setAndroidPackageId] = useState('');

	function verifyPlayStoreJsonFile(): void {
		devController.verifyPlayStoreJsonFile(apiKey, jsonVerify);
	}

	function invalidatePlayStoreData(): void {
		devController.invalidatePlayStoreData(apiKey);
	}

	function savePlayStoreData(): void {
		devController.savePlayStoreData(androidPackageId, jsonSave);
	}

	return (
		<SafeArea maxWidth='1000px'>
			<Spacer vertical={tokens.spacing.x6} />
			<Box>
				<Stack gap={tokens.spacing.x6}>
					<InputText label='Backend AppBuild API Key' name='apiKey' type='text' onChange={(e) => setApiKey(e.target.value)} />

					<Box borderRadius={tokens.borderRadius.medium} background={tokens.color.bg.surfaceBrand} padding={tokens.spacing.x2_5}>
						<InputTextArea name='jsonVerify' label='JSON To verify' onChange={(e) => setVerifyJson(e.target.value)} />
						<Button size='medium' variant='ghost' onClick={verifyPlayStoreJsonFile}>
							Verify JSON
						</Button>
					</Box>
					<Box borderRadius={tokens.borderRadius.medium} background={tokens.color.bg.surfaceBrand} padding={tokens.spacing.x2_5}>
						<InputTextArea name='jsonSave' label='JSON To save' onChange={(e) => setSaveJson(e.target.value)} />
						<InputTextArea name='androidPackageId' label='Android Package ID' onChange={(e) => setAndroidPackageId(e.target.value)} />
						<Button size='medium' variant='ghost' onClick={savePlayStoreData}>
							Save Play Store Data
						</Button>
					</Box>
					<Button size='medium' variant='primary' onClick={invalidatePlayStoreData}>
						Invalidate Play Store Data (ONLY CLICK THIS ONCE!)
					</Button>
				</Stack>
			</Box>

			<Spacer vertical={tokens.spacing.x9} />
		</SafeArea>
	);
}
