import { Transient, __registerMetaData } from '@visiba-cortex/instantiation';
import { HttpService, VisibaApiPromise } from '@application/http.service';
import { HttpOptions } from '@visiba-cortex/http';

@Transient()
export class AppBuildApiService {
	constructor(private readonly httpService: HttpService) {
		// Empty
	}

	private getHttpOptionsWithApiKeyHeader(apiKey: string): HttpOptions {
		return new HttpOptions({
			requestConfig: {
				headers: {
					'X-Visiba-Care-API-Key': apiKey,
				},
			},
		});
	}

	public getAndroidPlayStoreData(apiKey: string, androidPackageId: string): VisibaApiPromise<void> {
		return this.httpService.get(`appbuild/${androidPackageId}`, this.getHttpOptionsWithApiKeyHeader(apiKey));
	}

	public getIosAppStoreData(apiKey: string, iosBundleId: string): VisibaApiPromise<void> {
		return this.httpService.get(`appbuild/${iosBundleId}`, this.getHttpOptionsWithApiKeyHeader(apiKey));
	}

	public invalidatePlayStoreData(apiKey: string): VisibaApiPromise<boolean> {
		return this.httpService.get('appbuild/invalidate', this.getHttpOptionsWithApiKeyHeader(apiKey));
	}

	public verifyPlayStoreJsonFile(apiKey: string, apiAccessJsonContent: string): Promise<VisibaApiPromise<boolean>> {
		return this.httpService.post('appbuild/${appDataId}', apiAccessJsonContent, this.getHttpOptionsWithApiKeyHeader(apiKey));
	}
}
/* A vite plugin will eventually generate this */ __registerMetaData(AppBuildApiService, [HttpService]);
