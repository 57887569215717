import { tokens } from '@cellula/react-theme-patient';
import styled from '@emotion/styled';
import { Text } from '@visiba-cortex/react-ui-std';
import type { ReactNode } from 'react';

interface Props {
	children: ReactNode;
}

const StyledContainer = styled.div`
	width: 100%;
	padding: ${tokens.spacing.x3} ${tokens.spacing.x2};
	color: ${tokens.color.content.muted};
	border: 1px dashed ${tokens.color.border.default};
	border-radius: 12px;
	text-align: center;
`;

export function EmptyState({ children }: Props): JSX.Element {
	return (
		<StyledContainer>
			<Text font={tokens.text.label.regular}>{children}</Text>
		</StyledContainer>
	);
}
