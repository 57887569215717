import { InputText, InputTextProps } from '@cellula/react';
import { useState, ChangeEvent } from 'react';

type Props = {
	maxChars: number;
} & InputTextProps;

export function CharCountedInputText({
	helperText: helperTextSuffix,
	invalidText,
	onChange,
	maxChars,
	invalid,
	...rest
}: Props): JSX.Element {
	const [count, setCount] = useState((typeof rest.defaultValue === 'string' ? rest.defaultValue : '').length);

	function handleChange(event: ChangeEvent<HTMLInputElement>): void {
		setCount(event.target.value.length);
		onChange?.(event);
	}

	const helperText = `${count} of ${maxChars}.`;

	return (
		<InputText
			{...rest}
			onChange={handleChange}
			helperText={[helperText, helperTextSuffix].join(' ')}
			invalid={invalid || count > maxChars}
			invalidText={[helperText, invalidText || helperTextSuffix].join(' ')}
		/>
	);
}
