/* tslint:disable */
/* eslint-disable */
export enum AppDataValidationError {
  InvalidEmail = 'InvalidEmail',
  InvalidUrl = 'InvalidUrl',
  MissingAppName = 'MissingAppName',
  MissingAppDisplayName = 'MissingAppDisplayName',
  MissingEmail = 'MissingEmail',
  MissingUrl = 'MissingUrl',
  MissingTags = 'MissingTags',
  MissingShortDescription = 'MissingShortDescription',
  MissingLongDescription = 'MissingLongDescription',
  MissingIosBundleId = 'MissingIosBundleId',
  MissingAndroidPackageId = 'MissingAndroidPackageId',
  NoCountriesSpecified = 'NoCountriesSpecified',
  TooLongAppName = 'TooLongAppName',
  TooLongAppDisplayName = 'TooLongAppDisplayName',
  TooLongTags = 'TooLongTags',
  TooLongShortDescription = 'TooLongShortDescription',
  TooLongLongDescription = 'TooLongLongDescription'
}
