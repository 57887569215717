// Reason: While this prohibition remains valid for most cases,
//         ConcurrentPresentation is using it internally so we need the type to
//         match.
/* eslint-disable @typescript-eslint/ban-types */
import { Fragment, Suspense } from 'react';
import { ConcurrentPresentation, usePresentation } from '@visiba-cortex/presentation';

interface ClassWithPresentation<T extends object> {
	presentation: ConcurrentPresentation<T>;
}

interface Props<T extends object> {
	fallback: JSX.Element;
	controller: ClassWithPresentation<T>;
	children: (props: T) => JSX.Element | JSX.Element[] | null;
}

function PresentationReader<T extends object>(props: Props<T>): JSX.Element {
	const data = usePresentation(props.controller.presentation);

	return <Fragment>{props.children(data)}</Fragment>;
}

export function SuspendedPresentation<T extends object>(props: Props<T>): JSX.Element {
	return (
		<Suspense fallback={props.fallback}>
			<PresentationReader {...props} />
		</Suspense>
	);
}
