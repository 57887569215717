import { Sheet, IconButton, HeadlessButton, Stack, NotificationAlert, Flex, ImperativeSheetRef, Icon } from '@cellula/react';
import styled from '@emotion/styled';
import { AppPublishedState, AppListingPlayStoreInfoApiModel } from '@api/generated/models';
import { PublishedBadge } from '@components/published_badge.component';
import { Text } from '@visiba-cortex/react-ui-std';
import { tokens } from '@cellula/react-theme-patient';
import { useRef } from 'react';
import { HomeCustomerAppInfoForm } from './home_customer_app_info_form/mod';

interface AppDataProps {
	comment: string | null;
	appListingId: number;
	appName: string;
	appPublishedState: AppPublishedState;
	appListingPlayStoreInfoApiModel: AppListingPlayStoreInfoApiModel | null
	isLocalPinned: boolean;
	isGlobalPinned: boolean;
	onTogglePinned: (appListingId: number, isPinGlobal: boolean) => void;
	onClose(): void;
}

const StyledPositionalWrapper = styled.div`
	position: relative;
`;

const AppSheetFormButton = styled(HeadlessButton)`
	all: unset;
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	min-height: 56px;
	background-color: #ffff;
	width: 100%;
	padding: ${tokens.spacing.x2};
	box-sizing: border-box;
	box-shadow: ${tokens.elevation.skim};
	border-radius: ${tokens.borderRadius.medium};

	&:hover {
		box-shadow: ${tokens.elevation.lifted};
	}

	&:active {
		box-shadow: none;
	}
`;

const StyledPinContainer = styled.div`
	position: absolute;
	top: ${tokens.spacing.x1_5};
	right: ${tokens.spacing.x1_5};
`;

export function CustomerAppListItem(props: AppDataProps): JSX.Element {
	function handlePinClick(isPinGlobal: boolean): void {
		props.onTogglePinned(props.appListingId, isPinGlobal);
	}

	const sheetRef = useRef<ImperativeSheetRef>(null);
	const showBothPinButtons = !props.isGlobalPinned && !props.isLocalPinned;

	return (
		<StyledPositionalWrapper>
			<Sheet
				description='[only for accessibility]'
				onClose={props.onClose}
				ref={sheetRef}
				trigger={
					<AppSheetFormButton>
						<Stack gap={tokens.spacing.x3}>
							<Flex asChild align='center'>
								<Stack direction='horizontal' gap={tokens.spacing.x2}>
									<Text font={tokens.text.label.largeSemiBold}>{props.appName}</Text>
									<PublishedBadge appPublishedState={props.appPublishedState} />
								</Stack>
							</Flex>

							{props.comment ? <NotificationAlert variant='warning'>{props.comment}</NotificationAlert> : null}				

							<Stack direction='horizontal' gap={tokens.spacing.x2}>
								<Text font={tokens.text.label.semiBold}>Latest uploaded version:</Text>
								<Stack direction='horizontal' gap={tokens.spacing.x1}>
									<Icon type={'playStoreLogo'}></Icon>
									<Text font={tokens.text.label.regular}>{props.appListingPlayStoreInfoApiModel?.playStoreVersionName ?? "Missing or invalid cert"}</Text>
									<Text font={tokens.text.body.smallSemiBold}>({props.appListingPlayStoreInfoApiModel?.lastPlayStoreUpdateTimestamp?.toLocaleString().split('T')[0] ?? 'Unkown last updated time'})</Text>
								</Stack>
							</Stack>
						</Stack>
					</AppSheetFormButton>
				}
			>
				<HomeCustomerAppInfoForm
					appId={props.appListingId}
					sheetRef={sheetRef}
					onClose={props.onClose}
					appListingName={props.appName}
				/>
			</Sheet>
			<StyledPinContainer>
				<Stack direction='horizontal' gap={tokens.spacing.x1}>
					{(props.isLocalPinned || showBothPinButtons) && 
						<IconButton
							variant='neutral'
							size='medium'
							icon={props.isLocalPinned ? 'pinFilled' : 'pin'}
							onClick={() => handlePinClick(false)}
							text={props.isLocalPinned ? 'unpin locally' : 'pin locally'}
						/>
					}
					{(props.isGlobalPinned || showBothPinButtons) && 
						<IconButton
							variant='neutral'
							size='medium'
							icon={props.isGlobalPinned ? 'minus' : 'plus'}
							onClick={() => handlePinClick(true)}
							text={props.isGlobalPinned ? 'unpin globally' : 'pin globally'}
						/>
					}
				</Stack>
			</StyledPinContainer>
		</StyledPositionalWrapper>
	);
}
