import { Singleton, __registerMetaData } from '@visiba-cortex/instantiation';

@Singleton()
export class EnvironmentService {
	public readonly apiBaseUri: string;

	constructor() {
		this.apiBaseUri = import.meta.env.PROD ? '/api' : 'https://localhost:7113/api';
	}
}
/* A vite plugin will eventually generate this */ __registerMetaData(EnvironmentService, []);
