import { Fragment, ReactNode } from 'react';
import { LocalPushNotifications } from './local_push_notifications.component';

interface Props {
	children: ReactNode;
}

export function NotificationProvider({ children }: Props): JSX.Element {
	return (
		<Fragment>
			{children}
			<LocalPushNotifications />
		</Fragment>
	);
}
