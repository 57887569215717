/* tslint:disable */
/* eslint-disable */

/**
 * Maps to VisibaCare.MobileAppAdmin.Dal.Contract.File.FileImageType with Android and iOS app icons merged into one. Because for frontend it is not relevant to be able to see the difference between the two
 */
export enum FileImageTypeApiModel {
  Invalid = 'Invalid',
  AndroidFeatureGraphic = 'AndroidFeatureGraphic',
  AndroidPhoneScreenShots = 'AndroidPhoneScreenShots',
  AndroidTabletScreenShots = 'AndroidTabletScreenShots',
  AndroidLargeTabletScreenShots = 'AndroidLargeTabletScreenShots',
  AppIcon = 'AppIcon',
  IosIPhoneScreenShots = 'IosIPhoneScreenShots',
  IosIPhoneProScreenShots = 'IosIPhoneProScreenShots',
  IosIpadScreenShots = 'IosIpadScreenShots',
  IosIpadProScreenShots = 'IosIpadProScreenShots'
}
