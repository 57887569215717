/* tslint:disable */
/* eslint-disable */
export enum FileValidationError {
  MissingIcon = 'MissingIcon',
  MissingFeatureImage = 'MissingFeatureImage',
  TooManyIcons = 'TooManyIcons',
  TooManyFeatureImages = 'TooManyFeatureImages',
  TooFewAndroidPhoneScreenshots = 'TooFewAndroidPhoneScreenshots',
  TooManyAndroidPhoneScreenshots = 'TooManyAndroidPhoneScreenshots',
  TooFewAndroidTabletScreenshots = 'TooFewAndroidTabletScreenshots',
  TooManyAndroidTabletScreenshots = 'TooManyAndroidTabletScreenshots',
  TooFewAndroidLargeTabletScreenshots = 'TooFewAndroidLargeTabletScreenshots',
  TooManyAndroidLargeTabletScreenshots = 'TooManyAndroidLargeTabletScreenshots',
  TooFewIosPhoneScreenshots = 'TooFewIosPhoneScreenshots',
  TooManyIosPhoneScreenshots = 'TooManyIosPhoneScreenshots',
  TooFewIosPhoneProScreenshots = 'TooFewIosPhoneProScreenshots',
  TooManyIosPhoneProScreenshots = 'TooManyIosPhoneProScreenshots',
  TooFewIosIpadScreenshots = 'TooFewIosIpadScreenshots',
  TooManyIosIpadScreenshots = 'TooManyIosIpadScreenshots',
  TooFewIosIpadProScreenshots = 'TooFewIosIpadProScreenshots',
  TooManyIosIpadProScreenshots = 'TooManyIosIpadProScreenshots'
}
