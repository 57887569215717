import { SafeArea, Spacer } from "@cellula/react";
import { tokens } from "@cellula/react-theme-patient";
import { useParams } from "react-router-dom";

interface Props {}

export function Video(_: Props): JSX.Element {
    const { videoname } = useParams<{ videoname: string }>();

    const baseurl = `https://visibaappadmin.blob.core.windows.net/files/${videoname}.mp4`;

    const htmlContent = `
        <div>
            <h2>Data sync to track Place in drop-in queue</h2>
            <h2>Microphone for video call that is temporary in the background</h2>
            <video width="auto" style="object-fit: cover; max-height: 80vh" controls>
                <source src="${baseurl}" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>
    `;

    return (
        <SafeArea maxWidth='1000px'>
            <Spacer vertical={tokens.spacing.x6} />
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            <Spacer vertical={tokens.spacing.x9} />
        </SafeArea>
    );
}