import { resolvePath } from 'react-router';
import { Singleton, __registerMetaData } from '@visiba-cortex/instantiation';
import {
	BrowserHistory,
	BrowserHistoryOptions,
	createBrowserHistory,
	createMemoryHistory,
	MemoryHistory,
	MemoryHistoryOptions,
} from 'history';

interface NavigateOption {
	replace?: boolean;
	state?: unknown;
}

type ValidHistories = BrowserHistory | MemoryHistory;

/**
 * Hooks into the react-router module in order to enable navigation from one
 * route to the next as users perform application tasks programmatically.
 *
 * _Note: This service should be viewed as a alternative way to preform
 * navigation in the application._
 */
@Singleton()
export class RouterService {
	private internalHistory: ValidHistories | null = null;
	public get history(): ValidHistories {
		if (this.internalHistory == null) throw new HistoryNotSetError();

		return this.internalHistory;
	}

	constructor() {
		// Empty
	}

	// Text copied from https://github.com/ReactTraining/history/blob/master/packages/history/index.ts#L390
	/**
	 * Browser history stores the location in regular URLs. This is the standard for
	 * most web apps, but it requires some configuration on the server to ensure you
	 * serve the same app at multiple URLs.
	 */
	public createBrowserHistory(options?: BrowserHistoryOptions): BrowserHistory {
		if (this.internalHistory == null) {
			this.internalHistory = createBrowserHistory(options);
		}

		return this.internalHistory;
	}

	// Text copied from https://github.com/ReactTraining/history/blob/master/packages/history/index.ts#L877
	/**
	 * Memory history stores the current location in memory. It is designed for use
	 * in stateful non-browser environments like tests and React Native.
	 */
	public createMemoryHistory(options?: MemoryHistoryOptions): MemoryHistory {
		if (this.internalHistory == null) {
			this.internalHistory = createMemoryHistory(options);
		}

		return this.internalHistory as MemoryHistory;
	}

	/**
	 * Navigates to a view using an relative route path.
	 */
	public navigateByUrl(to: string | number, options: NavigateOption = {}): void {
		// Implements https://github.com/ReactTraining/react-router/blob/dev/packages/react-router/index.tsx#L420
		if (typeof to === 'number') {
			this.history.go(to);

			return;
		}

		const path = resolvePath(to, this.internalHistory?.location.pathname);

		if (options.replace) {
			this.history.replace(path, options.state);

			return;
		}

		this.history.push(path, options.state);
	}
}

export class HistoryNotSetError extends Error {
	constructor() {
		super('History is null, initialize one by calling the "createBrowserHistory" method');
		this.name = 'HistoryNotSetError';
		Object.setPrototypeOf(this, HistoryNotSetError.prototype);
	}
}

/* A vite plugin will eventually generate this */ __registerMetaData(RouterService, []);
