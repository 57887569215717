import { AppDataApiService } from '@api/appdata_api.service';
import { LocalPushNotification } from '@application/notification/local_push_notification';
import { NotificationService } from '@application/notification/notification.service';
import { Scoped, __registerMetaData } from '@visiba-cortex/instantiation';
import { Presentation as CustomerAppInfoPresentation, SynchronousPresentation } from '@visiba-cortex/presentation';
import {
	AppDataApiError,
	AppDataApiModel,
	AppDataApiSaveModel,
	AppPublishedState,
	AppDataValidationResult,
	AppDataValidationError,
	FileImageTypeApiModel,
	FileValidationResult,
	FileValidationError,
} from '@api/generated/models';
import { FormEvent, RefObject } from 'react';
import { isHttpError } from '@visiba-cortex/http';
import { delay } from '@visiba-cortex/std';
import confetti from 'canvas-confetti';
import { FileManagerService } from '@application/file_manager/file_manager.service';
import { Injector } from '@application/injector.service';
import { androidMeta, iconMeta, ImageMetaData, iOSMeta } from './home_customer_app_info_form_image_meta_data';
import { ImperativeSheetRef } from '@cellula/react';

interface Presentation {
	customerAppInfo: CustomerAppInfoController;
}

@Scoped()
export class CustomerAppInfoFormController {
	// This should not be called "suspendedPresentation" -> "presentation"
	public readonly presentation = CustomerAppInfoPresentation.createConcurrent<Presentation>();

	constructor(
		private readonly appDataApiService: AppDataApiService,
		private readonly notificationService: NotificationService,
		private readonly injector: Injector,
	) {
		// Empty
	}

	public async createCustomerAppInfo(appId: number, appListingName: string): Promise<void> {
		this.presentation.suspend(this.appDataApiService.get(appId), (data) => {
			return {
				customerAppInfo: new CustomerAppInfoController(
					appListingName,
					data,
					this.appDataApiService,
					this.notificationService,
					this.injector,
				),
			};
		});
	}
}
/* A vite plugin will eventually generate this */ __registerMetaData(CustomerAppInfoFormController, [
	AppDataApiService,
	NotificationService,
	Injector,
]);

interface CustomerAppInfoPresentation {
	canSaveDraft: boolean;
	validationResult: ValidationResult | null;
	waitingForResponse: boolean;
}

interface FileGroup {
	meta: ImageMetaData;
	fileManager: FileManagerService;
}

export class CustomerAppInfoController {
	public readonly presentation: SynchronousPresentation<CustomerAppInfoPresentation>;

	public readonly fetchedData: Readonly<AppDataApiModel>;
	public readonly fileGroupingIcon: Readonly<FileGroup[]>;
	public readonly fileGroupingIOS: Readonly<FileGroup[]>;
	public readonly fileGroupingAndroid: Readonly<FileGroup[]>;
	private readonly appId: number;

	constructor(
		public readonly appListingName: string,
		private readonly appDataApiModel: AppDataApiModel,
		private readonly appDataApiService: AppDataApiService,
		private readonly notificationService: NotificationService,
		private readonly injector: Injector,
	) {
		this.presentation = CustomerAppInfoPresentation.create<CustomerAppInfoPresentation>({
			canSaveDraft: appDataApiModel.appPublishedState === AppPublishedState.Published,
			validationResult: null,
			waitingForResponse: false,
		});

		this.appId = appDataApiModel.id;
		this.fetchedData = appDataApiModel;
		this.fileGroupingIcon = iconMeta.map((meta) => ({
			meta,
			fileManager: this.injector.resolve(FileManagerService),
		}));
		this.fileGroupingIOS = iOSMeta.map((meta) => ({
			meta,
			fileManager: this.injector.resolve(FileManagerService),
		}));
		this.fileGroupingAndroid = androidMeta.map((meta) => ({
			meta,
			fileManager: this.injector.resolve(FileManagerService),
		}));

		const allFileGroups = [...this.fileGroupingIOS, ...this.fileGroupingAndroid, ...this.fileGroupingIcon];
		for (const attachedFile of appDataApiModel.fileReferences?.sort((a, b) => {
			return a.sortOrder - b.sortOrder;
		}) ?? []) {
			const belongedFileGroup = allFileGroups.find((fileGroup) => fileGroup.meta.type === attachedFile.imageType);
			if (belongedFileGroup == null) continue;

			belongedFileGroup.fileManager.addStale(attachedFile.fileReference, this.appId);
		}
	}

	public async submit(form: FormEvent, sheetRef: RefObject<ImperativeSheetRef>): Promise<boolean> {
		if (this.fetchedData.appPublishedState === AppPublishedState.Published) {
			return await this.publish(form.target as HTMLFormElement, sheetRef);
		}

		return await this.saveDraft(form.target as HTMLFormElement);
	}

	public async delete(): Promise<void> {
		this.presentation.write({
			waitingForResponse: true,
		});

		await delay(150);

		await this.appDataApiService.deleteApp(this.appId).then(() => {
			delay(500).then(() => {
				this.notificationService.add(
					new LocalPushNotification({
						label: `${this.appListingName} has been removed 🧹`,
						type: 'toast',
						variant: 'success',
					}),
				);
			});
		});
	}

	public resetImages(imageType: FileImageTypeApiModel): void {
		const allFileGroups = [...this.fileGroupingIOS, ...this.fileGroupingAndroid, ...this.fileGroupingIcon];
		for (const group of allFileGroups) {
			if (group.meta.type !== imageType) continue;

			group.fileManager.dropAllWorkers();

			(this.appDataApiModel.fileReferences?.filter((file) => file.imageType === imageType) ?? []).forEach((file) => {
				group.fileManager.addStale(file.fileReference, this.appId);
			});

			break;
		}
	}

	public async publish(formDomElement: HTMLFormElement, sheetRef: RefObject<ImperativeSheetRef>): Promise<boolean> {
		var isSuccessful = true;

		try {
			const shouldBePublished = this.appDataApiModel.appPublishedState !== AppPublishedState.Published;

			const appSavingDataApiModel = this.extractFormData(formDomElement, AppPublishedState.Published);

			this.presentation.write({
				validationResult: null,
				waitingForResponse: true,
			});

			await this.appDataApiService.save(appSavingDataApiModel);

			sheetRef.current?.close();

			this.presentation.write({
				canSaveDraft: false,
				validationResult: null,
				waitingForResponse: true,
			});

			delay(300).then(() => {
				if (shouldBePublished) {
					this.throwUpSomeConfetti();
				}

				delay(500).then(() => {
					this.notificationService.add(
						new LocalPushNotification({
							label: `${this.appDataApiModel.appName} was ${shouldBePublished ? 'saved' : 'published'} 🤩`,
							type: 'toast',
							variant: 'success',
						}),
					);
				});
			});
		} catch (error: unknown) {
			if (isHttpError<AppDataApiError>(error)) {
				const validationResult = new AppDataValidator().validate(error.data);

				this.presentation.write({
					validationResult,
				});

				isSuccessful = false;
				return false;
			}

			throw error;
		} finally {
			this.presentation.write({
				waitingForResponse: false,
			});
			return isSuccessful;
		}
	}

	public async saveDraft(formDomElement: HTMLFormElement): Promise<boolean> {
		var isSuccessful = true;

		try {
			const appSavingDataApiModel = this.extractFormData(formDomElement, AppPublishedState.Draft);

			this.presentation.write({
				waitingForResponse: true,
			});

			await this.appDataApiService.saveDraft(appSavingDataApiModel);

			this.notificationService.add(
				new LocalPushNotification({
					label: 'Draft saved 👨‍🎨',
					type: 'toast',
					variant: 'success',
				}),
			);
		} catch (error: unknown) {
			if (isHttpError(error)) {
				console.log(error);
				
				isSuccessful = false;
				return false;
			}

			throw error;
		} finally {
			this.presentation.write({
				waitingForResponse: false,
			});
			return isSuccessful;
		}
	}

	public hasChanges(formDomElement: HTMLFormElement): boolean {
		const newData = this.extractFormData(formDomElement, AppPublishedState.Unknown);
		const oldData = this.appDataApiModel;

		// 🙈 sorry for this. Feel free to refactor to something cool.

		const arrayIsEqual = (a: string[], b: string[], onlyValues = false): boolean => {
			a = a.filter((x) => x !== '');
			b = b.filter((x) => x !== '');

			if (a.length !== b.length) return false;

			if (onlyValues) {
				const sum = a.concat(b);

				return new Set(sum).size === a.length;
			}

			if (JSON.stringify(a) !== JSON.stringify(b)) return false;

			return true;
		};

		const diffs: boolean[] = [
			(newData.actionNeeded ?? '') === (oldData.actionNeeded ?? ''),
			(newData.androidPackageId ?? '') === (oldData.androidPackageId ?? ''),
			(newData.appDisplayName ?? '') === (oldData.appDisplayName ?? ''),
			(newData.appName ?? '') === (oldData.appName ?? ''),
			arrayIsEqual(newData.availableCountryCodes ?? [], oldData.availableCountryCodes ?? []),
			(newData.email ?? '') === (oldData.email ?? ''),
			arrayIsEqual(
				newData.fileReferences ?? [],
				// The filter here is to remove enum data that does not exist anymore.
				oldData.fileReferences?.filter((x) => typeof x.imageType !== 'number').map((x) => x.fileReference) ?? [],
				true,
			),
			(newData.iosBundleId ?? '') === (oldData.iosBundleId ?? ''),
			(newData.langCode ?? '') === (oldData.langCode ?? ''),
			(newData.longDescription ?? '') === (oldData.longDescription ?? ''),
			(newData.shortDescription ?? '') === (oldData.shortDescription ?? ''),
			(newData.tagsJson ?? '') === (oldData.tagsJson ?? ''),
			(newData.url ?? '') === (oldData.url ?? ''),
		];

		return diffs.some((diff) => !diff);
	}

	private extractFormData(formDomElement: HTMLFormElement, publishState: AppPublishedState): AppDataApiSaveModel {
		const formData = new FormData(formDomElement);

		const allFileReferences: string[] = [];
		const mutateFromFileGroup = (fileGroupList: Readonly<FileGroup[]>): void => {
			for (const fileGroup of fileGroupList) {
				for (const worker of fileGroup.fileManager.peakUploadedWorkers()) {
					const referenceId = worker.isUploaded(true) as string | null;
					if (referenceId == null) {
						continue;
					}

					allFileReferences.push(referenceId);
				}
			}
		};

		mutateFromFileGroup(this.fileGroupingIcon);
		mutateFromFileGroup(this.fileGroupingIOS);
		mutateFromFileGroup(this.fileGroupingAndroid);

		const appSavingDataApiModel: AppDataApiSaveModel = {
			id: this.appId,
			appPublishedState: publishState,
			// No fields for these yet.
			availableCountryCodes: formData.get('availableCountryCodes')?.toString().split(',') || null,
			langCode: formData.get('langCode')?.toString() || this.fetchedData.langCode || null,
			// HTML form values.
			fileReferences: allFileReferences,
			actionNeeded: formData.get('actionNeeded')?.toString() || null,
			androidPackageId: formData.get('androidPackageId')?.toString() || null,
			iosBundleId: formData.get('iosBundleId')?.toString() || null,
			appName: formData.get('appName')?.toString() || null,
			appDisplayName: formData.get('appDisplayName')?.toString() || null,
			tagsJson: formData.get('tagsJson')?.toString() || null,
			url: formData.get('url')?.toString() || null,
			email: formData.get('email')?.toString() || null,
			shortDescription: formData.get('shortDescription')?.toString() || null,
			longDescription: formData.get('longDescription')?.toString() || null,
		};

		return appSavingDataApiModel;
	}

	private async throwUpSomeConfetti(): Promise<void> {
		const confettiInstances = Array.from({ length: 2 + Math.floor(Math.random() * 5) }, (_, i) => i);
		for (const _ of confettiInstances) {
			confetti({
				particleCount: 100,
				startVelocity: this.randomNumber(30, 40),
				spread: this.randomNumber(180, 200),
				origin: {
					x: Math.random(),
				},
			});

			await delay(this.randomNumber(80, 100));
		}
	}

	private randomNumber(min: number, max: number): number {
		return Math.floor(Math.random() * max) + min;
	}
}

interface ValidationStatus {
	isValid: boolean;
	errorMessage: string | undefined;
}

type ValidationResult = {
	appName: ValidationStatus;
	appDisplayName: ValidationStatus;
	androidPackageId: ValidationStatus;
	iosBundleId: ValidationStatus;
	tagsJson: ValidationStatus;
	url: ValidationStatus;
	email: ValidationStatus;
	shortDescription: ValidationStatus;
	longDescription: ValidationStatus;
	availableCountryCodes: ValidationStatus;
	images: Set<string>;
};

class AppDataValidator {
	private readonly requiredErrorMessage = 'This field is required';

	constructor() {
		//empty
	}

	public validate(error: AppDataApiError): ValidationResult {
		return {
			appName: this.mapApiAppNameValidation(error.appDataValidationResult),
			appDisplayName: this.mapApiAppDisplayNameValidation(error.appDataValidationResult),
			androidPackageId: this.mapApiAndroidPackageIdValidation(error.appDataValidationResult),
			iosBundleId: this.mapApiIosBundleIdValidation(error.appDataValidationResult),
			tagsJson: this.mapApiTagsValidation(error.appDataValidationResult),
			url: this.mapApiUrlValidation(error.appDataValidationResult),
			email: this.mapApiEmailValidation(error.appDataValidationResult),
			shortDescription: this.mapApiShortDescriptionValidation(error.appDataValidationResult),
			longDescription: this.mapApiLongDescriptionValidation(error.appDataValidationResult),
			availableCountryCodes: this.mapApiAvailableCountryCodesValidation(error.appDataValidationResult),
			images: this.mapApiImagesValidation(error.fileValidationResult),
		};
	}

	private mapApiAvailableCountryCodesValidation(appDataValidationResult: AppDataValidationResult): ValidationStatus {
		const isMissing = appDataValidationResult.appDataValidationErrors?.find((x) => x == AppDataValidationError.NoCountriesSpecified);
		const errorMessage = isMissing != null ? this.requiredErrorMessage : undefined;

		return { isValid: !isMissing, errorMessage: errorMessage };
	}

	private mapApiImagesValidation(appDataValidationResult: FileValidationResult): Set<string> {
		const set = new Set<string>();

		for (const errorStr of appDataValidationResult.fileValidationErrors ?? []) {
			switch (errorStr) {
				case FileValidationError.MissingIcon: {
					set.add('AppIcon');
					break;
				}
				case FileValidationError.TooManyIosIpadScreenshots:
				case FileValidationError.TooFewIosIpadScreenshots: {
					set.add('IosIpadScreenShots');
					break;
				}
				case FileValidationError.TooManyIosIpadProScreenshots:
				case FileValidationError.TooFewIosIpadProScreenshots: {
					set.add('IosIpadProScreenShots');
					break;
				}
				case FileValidationError.TooManyIosPhoneProScreenshots:
				case FileValidationError.TooFewIosPhoneProScreenshots: {
					set.add('IosIPhoneProScreenShots');
					break;
				}
				case FileValidationError.TooManyIosPhoneScreenshots:
				case FileValidationError.TooFewIosPhoneScreenshots: {
					set.add('IosIPhoneScreenShots');
					break;
				}
				case FileValidationError.TooFewAndroidPhoneScreenshots:
				case FileValidationError.TooManyAndroidPhoneScreenshots: {
					set.add('AndroidPhoneScreenShots');
					break;
				}
				default: {
					set.add(errorStr);
				}
			}
		}

		return set;
	}

	private mapApiAppNameValidation(appDataValidationResult: AppDataValidationResult): ValidationStatus {
		const isMissing = appDataValidationResult.appDataValidationErrors?.find((x) => x == AppDataValidationError.MissingAppName);
		const isTooLong = appDataValidationResult.appDataValidationErrors?.find((x) => x == AppDataValidationError.TooLongAppName);
		const isValid = isMissing == null && !isTooLong;
		const errorMessage = isTooLong ? 'Too long app name' : isMissing != null ? this.requiredErrorMessage : undefined;

		return { isValid: isValid, errorMessage: errorMessage };
	}

	private mapApiAndroidPackageIdValidation(appDataValidationResult: AppDataValidationResult): ValidationStatus {
		const isMissing = appDataValidationResult.appDataValidationErrors?.find(
			(x) => x == AppDataValidationError.MissingAndroidPackageId,
		);
		const isValid = isMissing == null;
		const errorMessage = isMissing != null ? this.requiredErrorMessage : undefined;

		return { isValid: isValid, errorMessage: errorMessage };
	}

	private mapApiIosBundleIdValidation(appDataValidationResult: AppDataValidationResult): ValidationStatus {
		const isMissing = appDataValidationResult.appDataValidationErrors?.find((x) => x == AppDataValidationError.MissingIosBundleId);
		const isValid = isMissing == null;
		const errorMessage = isMissing != null ? this.requiredErrorMessage : undefined;

		return { isValid: isValid, errorMessage: errorMessage };
	}

	private mapApiAppDisplayNameValidation(appDataValidationResult: AppDataValidationResult): ValidationStatus {
		const isMissing = appDataValidationResult.appDataValidationErrors?.find((x) => x == AppDataValidationError.MissingAppDisplayName);
		const isTooLong = appDataValidationResult.appDataValidationErrors?.find((x) => x == AppDataValidationError.TooLongAppDisplayName);

		const isValid = isMissing == null && !isTooLong;
		const errorMessage = isTooLong ? 'Too long app store display name' : isMissing != null ? this.requiredErrorMessage : undefined;

		return { isValid: isValid, errorMessage: errorMessage };
	}

	private mapApiTagsValidation(appDataValidationResult: AppDataValidationResult): ValidationStatus {
		const isMissing = appDataValidationResult.appDataValidationErrors?.find((x) => x == AppDataValidationError.MissingTags);
		const isValid = isMissing == null;
		const errorMessage = isMissing != null ? this.requiredErrorMessage : undefined;

		return { isValid: isValid, errorMessage: errorMessage };
	}

	private mapApiUrlValidation(appDataValidationResult: AppDataValidationResult): ValidationStatus {
		const isInvalid = appDataValidationResult.appDataValidationErrors?.find((x) => x == AppDataValidationError.InvalidUrl);
		const isMissing = appDataValidationResult.appDataValidationErrors?.find((x) => x == AppDataValidationError.MissingUrl);
		const isValid = isMissing == null && isInvalid == null;
		const errorMessage =
			isInvalid != null
				? 'Invalid format for URL. Example of correct format: https://visibacare.com'
				: isMissing != null
				? this.requiredErrorMessage
				: undefined;

		return { isValid: isValid, errorMessage: errorMessage };
	}

	private mapApiEmailValidation(appDataValidationResult: AppDataValidationResult): ValidationStatus {
		const isInvalid = appDataValidationResult.appDataValidationErrors?.find((x) => x == AppDataValidationError.InvalidEmail);
		const isMissing = appDataValidationResult.appDataValidationErrors?.find((x) => x == AppDataValidationError.MissingEmail);
		const isValid = isMissing == null && isInvalid == null;
		const errorMessage =
			isInvalid != null
				? 'Invalid format for Email. Example of correct format: email@visibacare.com'
				: isMissing != null
				? this.requiredErrorMessage
				: undefined;

		return { isValid: isValid, errorMessage: errorMessage };
	}

	private mapApiShortDescriptionValidation(appDataValidationResult: AppDataValidationResult): ValidationStatus {
		const isTooLong = appDataValidationResult.appDataValidationErrors?.find(
			(x) => x == AppDataValidationError.TooLongShortDescription,
		);
		const isMissing = appDataValidationResult.appDataValidationErrors?.find(
			(x) => x == AppDataValidationError.MissingShortDescription,
		);
		const isValid = isMissing == null && isTooLong == null;
		const errorMessage = isTooLong != null ? 'Too long short description' : isMissing != null ? this.requiredErrorMessage : undefined;

		return { isValid: isValid, errorMessage: errorMessage };
	}

	private mapApiLongDescriptionValidation(appDataValidationResult: AppDataValidationResult): ValidationStatus {
		const isTooLong = appDataValidationResult.appDataValidationErrors?.find((x) => x == AppDataValidationError.TooLongLongDescription);
		const isMissing = appDataValidationResult.appDataValidationErrors?.find((x) => x == AppDataValidationError.MissingLongDescription);
		const isValid = isMissing == null && isTooLong == null;
		const errorMessage = isTooLong != null ? 'Too long description' : isMissing != null ? this.requiredErrorMessage : undefined;

		return { isValid: isValid, errorMessage: errorMessage };
	}
}
