import { ApplicationNotification, ApplicationNotificationSpecificationBase } from './common_types';

type Type = 'toast';

export type Variants = 'danger' | 'warning' | 'success' | 'info';

interface Specification extends ApplicationNotificationSpecificationBase<LocalPushNotification> {
	type: Type;
	label: string;
	message?: string;
	variant: Variants;
}

export class LocalPushNotification extends ApplicationNotification {
	public readonly event = 'localPush';
	public readonly type: Type;
	public readonly label: string;
	public readonly message: string | null;
	public readonly variant: Variants;

	constructor(spec: Specification) {
		super();

		this.type = spec.type;
		this.label = spec.label;
		this.message = spec.message ?? null;
		this.variant = spec.variant;
	}
}
