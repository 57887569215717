import { Transient, __registerMetaData } from '@visiba-cortex/instantiation';
import { HttpService, VisibaApiPromise } from '@application/http.service';
import { UserModel } from './generated/models';

@Transient()
export class UserApiService {
	constructor(private readonly httpService: HttpService) {
		// Empty
	}

	public getIdentity(): VisibaApiPromise<UserModel> {
		return this.httpService.get('user');
	}
}
/* A vite plugin will eventually generate this */ __registerMetaData(UserApiService, [HttpService]);
