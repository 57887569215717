import { tokens } from '@cellula/react-theme-patient';
import { Text } from '@visiba-cortex/react-ui-std';

interface Props {}

export function NotFoundPage(_: Props): JSX.Element {
	return (
		<Text as='h1' font={tokens.text.body.largeSemiBold}>
			404
		</Text>
	);
}
