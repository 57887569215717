import { Transient, __registerMetaData } from '@visiba-cortex/instantiation';
import { HttpService, VisibaApiPromise } from '@application/http.service';
import { AppDataApiModel, AppDataApiSaveModel } from '@api/generated/models';

@Transient()
export class AppDataApiService {
	constructor(private readonly httpService: HttpService) {
		// Empty
	}

	public get(appDataId: number): VisibaApiPromise<AppDataApiModel> {
		return this.httpService.get(`appdata/${appDataId}`);
	}

	public save(appDataApiModel: AppDataApiSaveModel): VisibaApiPromise<void> {
		return this.httpService.post('appdata', appDataApiModel);
	}

	public saveDraft(appDataApiModel: AppDataApiSaveModel): VisibaApiPromise<void> {
		return this.httpService.post('appdata/draft', appDataApiModel);
	}

	public deleteApp(appDataId: number): Promise<VisibaApiPromise<void>> {
		return this.httpService.delete(`AppListing/${appDataId}`);
	}

	public delete(appDataId: number): VisibaApiPromise<void> {
		return this.httpService.delete(`appdata/${appDataId}`);
	}
}
/* A vite plugin will eventually generate this */ __registerMetaData(AppDataApiService, [HttpService]);
