import { Button } from '@cellula/react';
import { Text } from '@visiba-cortex/react-ui-std';
import styled from '@emotion/styled';
import { tokens } from '@cellula/react-theme-patient';
import { MICROSOFT_SIGN_IN_URL } from '../../config/constants';

interface Props {}

const SignInPageContainer = styled.div`
	display: flex;
	height: 90vh;
	justify-content: center;
	align-items: center;
`;
const SignInBox = styled.div`
	/* Frame 4 */
	box-sizing: border-box;
	/* Auto layout */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 72px 56px 88px;
	gap: 40px;

	position: relative;
	width: 648px;
	height: 299px;
	/* semantic/bg/surface */
	background: ${tokens.color.bg.surface};
	/* semantic/border/brand */
	border: 1px solid ${tokens.color.border.brand};
	border-radius: ${tokens.borderRadius.medium};
`;

export function SignIn(_: Props): JSX.Element {
	return (
		<SignInPageContainer>
			<SignInBox>
				<Text as='h1' font={tokens.text.heading.medium}>
					Sign in to continue
				</Text>
				<a href={MICROSOFT_SIGN_IN_URL}>
					<Button size='xLarge' variant='primary'>
						Sign in with Microsoft
					</Button>
				</a>
			</SignInBox>
		</SignInPageContainer>
	);
}
