import { v4 } from 'uuid';

export type KnownApplicationNotificationTypes = 'localPush';

export type ImplementRecordWithKnownKeys<T extends string | number | symbol, K extends Record<T, any>> = { [key in keyof K]: K[key] };

export abstract class ApplicationNotification<T extends string = KnownApplicationNotificationTypes> {
	public readonly guid: string;
	public readonly timestamp: Date;
	public abstract readonly event: T;

	constructor(sid?: string) {
		this.timestamp = new Date();
		this.guid = sid ?? v4();
	}
}

export interface ApplicationNotificationSpecificationBase<_T extends ApplicationNotification> {
	guid?: string;
}
