import { Transient, __registerMetaData } from '@visiba-cortex/instantiation';
import { Http, HttpCancelTokenSource, HttpOptions, HttpResponse } from '@visiba-cortex/http';
import { EnvironmentService } from './environment.service';
import { FileApiModel, FileApiSortingInputModel } from '@api/generated/models';

@Transient()
export class HttpFileServer {
	constructor(private readonly environmentService: EnvironmentService) {
		// Empty
	}

	public identity(endpoint: string, name: string, size: number, type: string): Promise<any> {
		const options = new HttpOptions({
			requestConfig: {
				withCredentials: true,
				headers: {
					'Content-Type': 'application/json',
					Pragma: 'no-cache',
					'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
					'Cache-Control': 'no-cache, no-store, must-revalidate',
				},
			},
		});

		const request = Http.post<any>(
			this.environmentService.apiBaseUri + '/' + endpoint,
			{
				Name: name,
				Length: size,
				Type: type,
			},
			options,
		).then((response) => response.data.Data);

		return request;
	}

	public upload(file: File, absoluteUrl: string): [request: Promise<HttpResponse<FileApiModel>>, cancelSource: HttpCancelTokenSource] {
		const formData = new FormData();
		formData.append('uploads[]', file, file.name);

		const cancelSource = Http.createCancelTokenSource();
		const options = new HttpOptions({
			cancelToken: cancelSource,
			requestConfig: {
				withCredentials: true,
			},
		});

		const request = Http.post<FileApiModel>(absoluteUrl, formData, options);

		return [request, cancelSource];
	}

	public updateSortOrder(appDataId: number, data: FileApiSortingInputModel[]): Promise<HttpResponse<FileApiModel>> {
		const options = new HttpOptions({
			requestConfig: {
				withCredentials: true,
			},
		});

		return Http.put(this.environmentService.apiBaseUri + `/file/Sort?appDataId=${appDataId}`, data, options);
	}

	public async remove(absoluteUrl: string): Promise<HttpResponse<void>> {
		const options = new HttpOptions({
			requestConfig: {
				headers: {
					'Content-Type': 'application/json',
					Pragma: 'no-cache',
					'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
					'Cache-Control': 'no-cache, no-store, must-revalidate',
				},
			},
		});

		return Http.delete<void>(absoluteUrl, options);
	}
}
/* A vite plugin will eventually generate this */ __registerMetaData(HttpFileServer, [EnvironmentService]);
